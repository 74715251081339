import React, { useState, useMemo } from 'react';
import PageLayout from './page-layout';
import { useParams } from '@reach/router';
import { withStyles } from '@material-ui/core';
import { getTime } from 'date-fns';
import CanvasCompress from 'canvas-compress';
import { useChild } from '../lib/hooks/use-user';
import { get } from 'lodash';
import ProfileForm from './organisim/profile-form';
import { createUser } from '../lib/mutations/create-user';
import { useBeltSize } from '../lib/hooks/use-belt-size';
import { useGymPrograms } from '../lib/hooks/use-gym-program';
import { addAvatarImage } from '../lib/mutations/add-avatar';
import { navigate } from '@reach/router';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line

const styles = {
  textField: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  avatar: {
    margin: '8px',
    width: '100px',
    height: '100px',
    border: '4px solid #03a9f4',
  },
  inputFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    position: 'absolute',
    zIndex: '-1',
  },
};

const NewChild = (props) => {
  const { classes } = props;
  const { userId: parentId } = useParams();
  const { child, setChild } = useChild(parentId);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const uid = useMemo(() => uuidv4(), []);

  const { beltSizes = [] } = useBeltSize(get(child, 'gym.id'));
  const { gymPrograms = [] } = useGymPrograms(get(child, 'gym.id'));

  const updateTextField = (e, fieldName) => {
    const updatedState = {};
    const errors = child.errors;
    updatedState[`${fieldName}`] = e.target.value;
    const errorIndex = errors.indexOf(fieldName);
    if (errorIndex > -1 && fieldName !== 'degrees') {
      errors.splice(errorIndex, 1);
      updatedState['errors'] = errors;
    }
    setChild({ ...child, ...updatedState, uid });
  };

  const updateSelectField = (e, fieldName) => {
    const updatedState = {};
    updatedState[`${fieldName}`] = e.target.value;
    setChild({ ...child, ...updatedState, uid });
  };

  const uploadImage = (e) => {
    const { errors } = child;
    const file = e.target.files[0];
    const blob = file.slice(0, file.size, 'image/jpeg');

    const compressor = new CanvasCompress({
      type: CanvasCompress.MIME.JPEG,
      width: 500,
      height: 500,
      quality: 0.3,
    });

    compressor.process(blob).then(({ source, result }) => {
      const { blob } = result;
      const newFile = new File([blob], `${uid}.jpeg`, {
        type: 'image/jpeg',
      });
      if (newFile) {
        setChild({ ...child, avatar_url: '' });

        setIsUploadingImage(true);
        addAvatarImage(newFile)
          .then((avatar) => {
            const errorIndex = errors.indexOf('avatar_url');
            if (errorIndex > -1) {
              errors.splice(errorIndex, 1);
            }
            setChild({
              ...child,
              errors,
              avatar_url: avatar,
            });
            setIsUploadingImage(false);
          })
          .catch((err) => {
            console.log(err);
            setIsUploadingImage(false);
          });
      }
    });
  };

  const submitForm = () => {
    const formData = Object.assign({}, child);
    const errors = [];
    const includedFields = [
      'name',
      'email',
      'avatar_url',
      'belt',
      'belt_size',
      'gym',
      'jits_start_date',
      'start_date',
      'dob',
      'description',
    ];
    Object.keys(formData).forEach((key) => {
      if (key === 'jits_start_date' || key === 'start_date' || key === 'dob') {
        formData[key] = getTime(formData[key]);
      }
      if (!formData[key] && includedFields.includes(key)) {
        errors.push(key);
      } else {
        const errorIndex = errors.indexOf(key);
        if (errorIndex > -1) {
          errors.splice(errorIndex, 1);
        }
      }
    });

    if (errors.length > 0) {
      setChild((currentState) => ({
        ...child,
        ...currentState,
        errors,
      }));
      return;
    } else {
      delete formData['errors'];
      setChild((currentState) => ({
        ...child,
        ...currentState,
        errors,
      }));
    }

    createUser(formData)
      .then((res) => {
        navigate(`/profile/${res.uid}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <PageLayout pageTitle="Create Child">
      <ProfileForm
        submitForm={submitForm}
        classes={classes}
        beltSizes={beltSizes}
        gymPrograms={gymPrograms}
        user={child}
        setState={setChild}
        updateTextField={updateTextField}
        updateSelectField={updateSelectField}
        uploadImage={uploadImage}
        isImageUploading={isUploadingImage}
        isFormDirty={isFormDirty}
        setIsFormDirty={setIsFormDirty}
      />
    </PageLayout>
  );
};

export default withStyles(styles)(NewChild);
