import styled from 'styled-components';

export const ChartContainer = styled.div`
  height: 320px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const ChartOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 320px;
  z-index: 1;
  background: transparent;
`;
