import React, { useEffect, useState } from 'react';
import PageLayout from './page-layout';
import { withStyles } from '@material-ui/core/styles';
import { Box, Flex } from '@rebass/grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import firebase from 'firebase';
import { TextField, Button, Typography } from '@material-ui/core';
import { getGymByInvite } from '../lib/fetchers/gyms';
import { Link } from '@reach/router';

const styles = {
  avatar: {
    margin: 8,
    width: '100px',
    height: '100px',
  },
  button: {
    width: '100%',
    margin: '16px 0 8px',
  },
};

const history = () => {
  return <span>Login</span>;
};

const Invite = () => {
  useEffect(() => {
    firebase.auth().signOut();
  });
  const [inviteCode, setInviteCode] = useState('');
  const [error, setError] = useState(null);

  const handleInviteCodeSubmit = async () => {
    try {
      const gym = await getGymByInvite(inviteCode);
      const gymUUID = gym ? gym.uuid : null;
      if (gym && gymUUID) {
        window.location.replace(`login/${gymUUID}`);
        setError(null);
      } else {
        setError('Invalid code');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageLayout pageTitle="Enter Invitation" leftButton={history}>
      <Flex
        mb="16px"
        mt="100px"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Card
          style={{
            width: '100%',
            maxWidth: '380px',
          }}
        >
          <CardContent>
            <Box mb="16px">
              <TextField
                style={{
                  width: '100%',
                }}
                label="Enter Invitation code"
                onChange={(e) => setInviteCode(e.target.value)}
                error={!!error}
              />
            </Box>
            <Button
              style={{
                width: '100%',
              }}
              variant="outlined"
              color="primary"
              onClick={handleInviteCodeSubmit}
              error={!!error}
            >
              Submit
            </Button>
            <Box mt="16px">
              {!!error && <Typography color="error">{error}</Typography>}
            </Box>
          </CardContent>
        </Card>
        <Box
          mt="16px"
          style={{
            textAlign: 'left',
          }}
        >
          <Link to="/login">
            <Typography>Already have an account? </Typography>
          </Link>
        </Box>
      </Flex>
      <Box mb="16px" />
    </PageLayout>
  );
};

export default withStyles(styles)(Invite);
