import appConfig from '../../config/app';
import { getAuthHeaders } from '../fetchers/auth';

/*
@param {Object} disableConfig
enable_user: {
  user_id: number
}
*/

export async function enableUser(enableConfig) {
  const options = await getAuthHeaders();
  try {
    const res = await fetch(
      `${appConfig.baseApiUrl}/api/v1/admin/enable_user`,
      {
        method: 'POST',
        headers: {
          ...options,
        },
        body: JSON.stringify(enableConfig),
      },
    );

    return await res.json();
  } catch (error) {
    console.log(error);
  }
}
