import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import firebaseui from 'firebaseui';
import PageLayout from './page-layout';
import styled from 'styled-components';
import get from 'lodash.get';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createUser } from '../lib/mutations/create-user';
import { fetchUserInfo } from '../lib/fetchers/users';
import { getGym } from '../lib/fetchers/gyms';
import Cookies from 'js-cookie';
import { Link } from '@reach/router';
import Box from '@material-ui/core/Box';
import UserAvatar from './atom/user-avatar';

let config = {};
if (process.env.NODE_ENV === 'production') {
  config = {
    apiKey: 'AIzaSyDSEf7azzQchYob9r_rQKosRgy9kFq81QM',
    authDomain: 'mat-time-tracker-ffa2d.firebaseapp.com',
    projectId: 'mat-time-tracker-ffa2d',
    messagingSenderId: '1029879347499',
    storageBucket: 'mat-time-tracker-ffa2d.appspot.com',
  };
} else {
  config = {
    apiKey: 'AIzaSyCmhxJ2Hja8hmMvxzwAKt4-lxnz0cGCyck',
    authDomain: 'gym-checkin-staging.firebaseapp.com',
    projectId: 'gym-checkin-staging',
    messagingSenderId: '935943838125',
    storageBucket: 'gym-checkin-staging.appspot.com',
    databaseURL: 'https://gym-checkin-staging.firebaseio.com',
  };
}

firebase.initializeApp(config);

function checkForGymUUIDInURL() {
  const pathnameArray = get(window, 'location.pathname', []).split('/');
  const gymUUID = pathnameArray.length === 3 ? pathnameArray[2] : 0;
  return gymUUID ? gymUUID : null;
}

async function successCallSignIn(authObj) {
  // this is to accomidate two different shapes coming back from firebase
  const user = authObj.user ? authObj.user : authObj;
  const gymUUID = checkForGymUUIDInURL();
  const userObj = await fetchUserInfo(user.uid);
  const isAdmin = get(userObj, 'permissions', []).includes('admin');
  const isKiosk = get(userObj, 'permissions', []).includes('kiosk');

  if (userObj && userObj.uid) {
    const userGymUUID = get(userObj, 'gym.uuid');

    if (isAdmin && userGymUUID) {
      window.location.replace(`/admin/dashboard/${userGymUUID}`);
      return false;
    }

    if (isKiosk && userGymUUID) {
      window.location.replace(`/admin/gyms/${userGymUUID}/kiosk`);
      return false;
    }

    window.location.replace(`/profile/${user.uid}`);
    return false;
  }

  if (user.displayName && gymUUID) {
    const gym = await getGym(gymUUID);
    if (gym && gym.id) {
      try {
        const newUser = await createUser(
          {
            name: user.displayName,
            email: user.email,
            gym: { id: gym.id },
            uid: user.uid,
          },
          user.uid,
        );

        if (newUser) {
          window.location.replace(`/profile/${user.uid}/edit/signup`);
          return false;
        }
      } catch (e) {
        console.log(e, 'oops something went wrong');
        if (gymUUID) {
          window.location.replace(`/login/${gymUUID}`);
        } else {
          window.location.replace(`/login`);
        }
        return false;
      }
    } else {
      window.location.replace(`/invite`);
      return false;
    }
  } else if (!gymUUID) {
    window.location.replace(`/invite`);
    return false;
  }

  return false;
}

const uiConfig = {
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  height: 65vh;
`;

const Login = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [gym, setGym] = useState({});

  useEffect(() => {
    async function fetchData() {
      const gymUUID = checkForGymUUIDInURL();
      if (gymUUID) {
        const gym = await getGym(gymUUID);
        setGym(gym);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    try {
      firebase.auth().onAuthStateChanged((authInfo) => {
        if (authInfo) {
          setShowLogin(false);
          authInfo
            .getIdTokenResult(true)
            .then((idTokenResult) => {
              const token = get(idTokenResult, 'token');
              Cookies.set('authToken', token);
              successCallSignIn(authInfo);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setShowLogin(true);
        }
      });
    } catch (e) {
      console.log(e, 'oops something wen wrong');
    }
  }, []);
  return (
    <PageLayout pageTitle="Login" leftButton={() => {}}>
      <Center>
        {gym && gym.name && showLogin && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {gym.logo_url && <UserAvatar src={gym.logo_url} />}
            <Box
              style={{
                textAlign: 'center',
              }}
            >
              <h2>Welcome to {gym?.name}'s sign up</h2>
              <h4>
                Please choose an email to create <br /> your account with.
              </h4>
            </Box>
          </Box>
        )}
        {showLogin ? (
          <Box
            style={{
              minWidth: '300px',
              width: '100%',
            }}
          >
            <Box mb="16px">
              <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
                className="wrapper-test"
              />
            </Box>
            {!gym.name && (
              <Box
                mb="16px"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Link to="/invite">Don't have an account? Sign up here.</Link>
              </Box>
            )}
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Center>
    </PageLayout>
  );
};

export default Login;
