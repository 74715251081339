import { action, observable, runInAction, decorate } from "mobx";
import firebase from "firebase";

const db = firebase.firestore();
const storage = firebase.storage();
const settings = { timestampsInSnapshots: true };
db.settings(settings);
const gymRef = db.collection("gyms");

class Gym {
  currentGym = {};
  setGym(gymObj) {
    this.currentGym = gymObj;
  }

  addAvatarImage(file) {
    if (file && file.name) {
      const fileRef = storage.ref(`gym_avatars/${file.name}`);
      fileRef.put(file).then(res => {
        console.log(res.task.uploadUrl_);
      });
    }
  }

  fetchGymData(gymId) {
    return gymRef
      .doc(`${gymId}`)
      .get()
      .then(gymInfo => {
        runInAction(() => {
          this.currentGym = gymInfo.data();
        });
      })
      .catch(error => {
        runInAction(() => {
          this.currentGym = {};
        });
      });
  }
}
decorate(Gym, {
  currentGym: observable,
  setGym: action.bound,
  fetchGymData: action.bound
});

const gymStore = new Gym();

export default gymStore;
