import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const earthRadiusMi = 3959; // Radius of the earth in miles
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceMi = earthRadiusMi * c;
  return distanceMi;
};
const deg2rad = (degrees) => {
  return degrees * (Math.PI / 180);
};

export const useLocationCheck = (latitude, longitude) => {
  const [manualCheck, setManualCheck] = useState(0);
  const [locating, setLocating] = useState(false);
  const [distance, setDistance] = useState(null);

  const { data, error } = useQuery(
    ['location', latitude, longitude, manualCheck],
    async () => {
      if ('geolocation' in navigator) {
        const options = {
          enableHighAccuracy: true, // Set the desired accuracy
          maximumAge: 0, // Force current locations only
        };

        return new Promise((resolve, reject) => {
          setLocating(true);
          navigator.geolocation.getCurrentPosition(
            (position) => {
              console.log(position);
              setLocating(false);
              const {
                latitude: userLatitude,
                longitude: userLongitude,
              } = position.coords;

              const tempDistance = calculateDistance(
                userLatitude,
                userLongitude,
                latitude,
                longitude,
              );

              resolve(tempDistance);
            },
            (error) => {
              setLocating(false);
              reject(error);
            },
            options,
          );
        });
      } else {
        throw new Error('Geolocation is not supported by your browser.');
      }
    },
    {
      enabled: Boolean(latitude && longitude),
    },
  );

  const handleManualCheck = () => {
    setManualCheck((prev) => prev + 1);
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('buttonPushed');
    }
  };

  const resetReturnValues = (data, error) => {
    setDistance(data);
  };

  useEffect(() => {
    if (data) {
      resetReturnValues(data, error);
    }
  }, [data]);

  return { distance, locating, error, handleManualCheck, manualCheck };
};

export default useLocationCheck;
