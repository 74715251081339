import appConfig from '../../config/app';
import { getAuthHeaders } from './auth';

export async function getBeltSize(gymId) {
  const options = await getAuthHeaders();
  return fetch(`${appConfig.baseApiUrl}/api/v1/gyms/${gymId}/belts_size`, {
    headers: {
      ...options,
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error(error));
}
