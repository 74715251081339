import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { VictoryPie } from 'victory';
import { ChartContainer, ChartOverlay } from './styles';

export const DemographicsCard = ({ kidsCount, adultsCount }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Demographics</Typography>
      </CardContent>
      <ChartContainer>
        <ChartOverlay />
        <VictoryPie
          innerRadius={100}
          colorScale={['#00E9A9', '#A900E9']}
          style={{
            labels: {
              fill: 'white',
            },
          }}
          data={[
            {
              x: `Kids ${kidsCount}`,
              y: kidsCount,
            },
            {
              x: `Adults ${adultsCount}`,
              y: adultsCount,
            },
          ]}
        />
      </ChartContainer>
    </Card>
  );
};

export default DemographicsCard;
