import React, { useState } from 'react';
import { useGymUsers } from '../../../lib/hooks/use-gym-users';
import UserListItem from '../../organisim/user-list-item';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Card from '@material-ui/core/Card';
import { Button, Divider } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Close from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { Flex, Box } from '@rebass/grid';
import Profile from './profile';
import KioskModal from './kiosk-modal';
import UserAvatar from '../../../components/atom/user-avatar';
import { logCheckIn } from '../../../lib/mutations/checkin';
import { useMutation } from 'react-query';
import CheckCircle from '@material-ui/icons/CheckCircle';
import TablePagination from '@mui/material/TablePagination';
import { useIsMobile } from '../../../lib/hooks/use-is-mobile';
import { LoadingDots } from '../../atom/loading-dots';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 50px;
  background: #03a9f4;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 16px;
  box-sizing: border-box;
`;
const UserListItemWrapper = styled.div`
  border-bottom: 1px solid #5a5858;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const KioskBody = styled.div`
  background: #303030;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  justify-content: center;
  align-items: center;
  color: #fff;
  height: 300px;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;

const Wrapper = styled.div``;

export const UserList = ({
  queryString,
  searchTerm,
  setSearchTerm,
  gymUid,
  kiosk,
  filters = {},
}) => {
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [activeMember, setActiveMember] = useState({});
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { isMobile } = useIsMobile();
  const {
    age = '',
    startDate = '',
    endDate = '',
    status: userStatus,
  } = filters;
  const { users = {}, loading } = useGymUsers(gymUid, searchTerm, {
    status: userStatus,
    currentPage: currentPage + 1,
    perPage: rowsPerPage,
    startDate,
    endDate,
    age,
  });

  const { mutate } = useMutation((data) => logCheckIn(data), {
    onSuccess: () => {
      setShowSuccessToast(true);
      setTimeout(() => {
        setSearchTerm('');
        setShowSuccessToast(false);
        setisDrawerOpen(false);
      }, 2000);
    },
  });

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const toggleDrawer = (member) => {
    setActiveMember(member);
    setisDrawerOpen(!isDrawerOpen);
  };

  const handleCheckin = (user) => {
    mutate(user);
  };

  return (
    <>
      <Wrapper>
        <Card>
          <CardContent>
            <HeaderWrapper mb="16px" justifyContent="space-between">
              <Box p="8px">
                <Typography variant="h6">Members</Typography>
              </Box>
              {!kiosk && (
                <TablePagination
                  style={{ color: 'white' }}
                  component="div"
                  count={users?.meta?.total_items || 0}
                  page={currentPage}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </HeaderWrapper>
            <Flex
              justifyContent="space-between"
              p="16px"
              mb="24px"
              style={{
                backgroundColor: 'rgb(86 86 86)',
              }}
            >
              <Typography variant="body1">Name</Typography>
            </Flex>
            <Divider />

            {users?.users?.length > 0 &&
              users?.users?.map((member) => (
                <UserListItemWrapper key={member.id}>
                  <div onClick={() => toggleDrawer(member)}>
                    <UserListItem user={member} />
                  </div>
                </UserListItemWrapper>
              ))}
            {users?.users?.length === 0 && !loading && (
              <Flex
                style={{
                  height: '200px',
                }}
                alignItems="center"
                justifyContent="center"
              >
                <div>No members found</div>
              </Flex>
            )}
            {loading && <LoadingDots />}
            {!kiosk && (
              <TablePagination
                style={{ color: 'white' }}
                component="div"
                count={users?.meta?.total_items || 0}
                page={currentPage}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </CardContent>
        </Card>
        {kiosk ? (
          <KioskModal isOpen={isDrawerOpen} onClose={toggleDrawer}>
            <div>
              <Header>
                <IconWrapper onClick={toggleDrawer}>
                  <Close />
                </IconWrapper>
                <Typography variant="h6">Member Profile</Typography>
                <div />
              </Header>
              <KioskBody>
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  {!showSuccessToast && (
                    <>
                      <Box mt="16px">
                        <UserAvatar src={activeMember.avatar_url} />
                      </Box>
                      <Typography variant="h6">{activeMember.name}</Typography>
                      <Box mt="22px" width="50%">
                        <Button
                          variant="outlined"
                          style={{ width: '100%' }}
                          color="primary"
                          onClick={() => handleCheckin(activeMember)}
                          disabled={false}
                        >
                          Check in
                        </Button>
                      </Box>
                    </>
                  )}
                  {showSuccessToast && (
                    <Box mt="16px" mb="32px">
                      <Flex justifyContent="center">
                        <CheckCircle style={{ fontSize: '100px' }} />
                      </Flex>
                      <Typography variant="h6">
                        {activeMember.name}, you're checked in!
                      </Typography>
                    </Box>
                  )}
                </Flex>
              </KioskBody>
            </div>
          </KioskModal>
        ) : (
          <Drawer
            anchor={isMobile ? 'bottom' : 'right'}
            open={isDrawerOpen}
            onClose={toggleDrawer}
            PaperProps={{
              style: {
                height: '100vh',
                width: isMobile ? '100%' : '60%',
                background: '#303030',
              },
            }}
            transitionDuration={{ enter: 300, exit: 250 }} // Adjust the animation duration (in milliseconds)
            BackdropProps={{ invisible: true }}
          >
            <div>
              <Header>
                <IconWrapper onClick={toggleDrawer}>
                  <Close />
                </IconWrapper>
                <Typography variant="h6">Member Profile</Typography>
                <div />
              </Header>
              <Profile user={activeMember} />
            </div>
          </Drawer>
        )}
      </Wrapper>
    </>
  );
};

export default UserList;
