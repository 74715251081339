import React from 'react';
import { Flex } from '@rebass/grid';
import Divider from '@material-ui/core/Divider';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

const SelectCircle = styled.div`
  width: 10px;
  height: 10px;
  padding: 3px;
  border-radius: 50%;
  border: 2px solid #a6a4a4;
  margin-right: 12px;
  background: ${({ selected }) => (selected ? '#03a9f4' : 'transparent')};
`;

const OptionLink = ({ classes, onClick, isSelected, label }) => {
  return (
    <>
      <Divider />
      <Flex
        className={classes.optionLink}
        alignItems="center"
        onClick={onClick}
      >
        <SelectCircle selected={isSelected} />
        {label}
      </Flex>
    </>
  );
};

const styles = {
  optionLink: {
    padding: '16px',
  },
};

export default withStyles(styles)(OptionLink);
