import { useState } from 'react';

export const useDrawer = () => {
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setisDrawerOpen((prevState) => !prevState);
  };

  const openDrawer = () => {
    setisDrawerOpen(true);
  };

  const closeDrawer = () => {
    setisDrawerOpen(false);
  };

  return {
    closeDrawer,
    openDrawer,
    isDrawerOpen,
    toggleDrawer,
  };
};

export default useDrawer;
