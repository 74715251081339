import React from 'react';
import Modal from '../../../components/organisim/modal';

export const KioskModal = ({ user, children, isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div>{children}</div>
    </Modal>
  );
};

export default KioskModal;
