import appConfig from '../../config/app';
import { get } from 'lodash';
import { getAuthHeaders } from '../fetchers/auth';

export async function createPromo(promoData, gymId) {
  const options = await getAuthHeaders();
  const promoInfo = {
    name: get(promoData, 'name'),
    description: get(promoData, 'description'),
    promotion_active_day_threshold: get(
      promoData,
      'promotion_active_day_threshold'
    ),
    startDate: get(promoData, 'startDate'),
    gym_id: get(promoData, 'gymId'),
    created_by: get(promoData, 'createdBy'),
    status: get(promoData, 'status'),
  };

  if (promoData) {
    return fetch(`${appConfig.baseApiUrl}/api/v1/promotions`, {
      method: 'POST',
      headers: {
        ...options,
      },
      body: JSON.stringify(promoInfo),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  }
}

export async function updatePromo(promoData, gymId) {
  const options = await getAuthHeaders();
  const promoInfo = {
    name: get(promoData, 'name'),
    description: get(promoData, 'description'),
    promotion_active_day_threshold: get(
      promoData,
      'promotion_active_day_threshold'
    ),
    startDate: get(promoData, 'startDate'),
    gym_id: get(promoData, 'gymId'),
    created_by: get(promoData, 'createdBy'),
    status: get(promoData, 'status'),
  };

  if (promoData) {
    return fetch(`${appConfig.baseApiUrl}/api/v1/promotions/${gymId}`, {
      method: 'PATCH',
      headers: {
        ...options,
      },
      body: JSON.stringify(promoInfo),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  }
}

export async function createOrUpdateUsersPromo(users) {
  const options = await getAuthHeaders();
  const userPromoData = users;

  if (userPromoData) {
    return fetch(`${appConfig.baseApiUrl}/api/v1/user_promotions`, {
      method: 'PATCH',
      headers: {
        ...options,
      },
      body: JSON.stringify(userPromoData),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  }
}

export async function deleteUsersPromo(users) {
  const options = await getAuthHeaders();
  const userPromoData = { ids: users };

  if (userPromoData) {
    return fetch(`${appConfig.baseApiUrl}/api/v1/user_promotions`, {
      method: 'DELETE',
      headers: {
        ...options,
      },
      body: JSON.stringify(userPromoData),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  }
}
