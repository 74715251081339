import React from 'react';

import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import { VictoryPie } from 'victory';
import { Flex } from '@rebass/grid';
import { Typography } from '@material-ui/core';
import Arrow from '@material-ui/icons/ArrowForwardIos';

const PieContainer = styled.div`
  width: 90px;
  margin-right: 10px;
  position: relative;
`;

const PieOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  z-index: 1;
  background: transparent;
`;

const PieCardContainer = styled.div`
  width: 100%;
  cursor: pointer;
`;

const PieCard = ({ mainValue, greyValue, color, title }) => {
  return (
    <Card>
      <PieCardContainer>
        <Flex justifyContent="space-between">
          <Flex>
            <PieContainer>
              <PieOverlay />
              <VictoryPie
                data={[
                  {
                    x: 'GreyValue',
                    y: greyValue,
                  },
                  {
                    x: 'Main Value',
                    y: mainValue,
                  },
                ]}
                colorScale={['#303030', color]}
                innerRadius={100}
                labels={() => null}
              />
            </PieContainer>

            <Flex justifyContent="center" flexDirection="column">
              <Typography variant="h6">{title}</Typography>
              <Typography variant="body1">
                {mainValue} {' members '}
              </Typography>
            </Flex>
          </Flex>
          <Flex justifyContent="flex-start" alignItems="center" mr="6px">
            <Arrow />
          </Flex>
        </Flex>
      </PieCardContainer>
    </Card>
  );
};

export default PieCard;
