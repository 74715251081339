import Cookie from 'js-cookie';
import firebase from 'firebase';

function parseJwt(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
}

function isTokenExpired(token) {
  const decodedToken = parseJwt(token);
  if (!decodedToken) {
    return true; // Assume expired or invalid if cannot decode
  }

  const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp
  return decodedToken.exp < currentTime;
}

export const getAuthHeaders = async () => {
  const authToken = Cookie.get('authToken');

  if (isTokenExpired(authToken) || !authToken) {
    return firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(function(idToken) {
        Cookie.set('authToken', idToken);
        return {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        };
      })
      .catch(function(error) {
        return {
          'Content-Type': 'application/json',
        };
      });
  } else {
    return Promise.resolve().then(() => {
      return {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };
    });
  }
};

export async function authenticatedFetch(url, authToken, options = {}) {
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };

  const fetchOptions = {
    ...options,
    headers: {
      ...headers,
      ...options.headers,
    },
  };

  const response = await fetch(url, fetchOptions);

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  const data = await response.json();
  return data;
}
