import React, { useEffect, useState } from 'react';
import PageLayout from './page-layout';
import { withStyles } from '@material-ui/core/styles';
import { Flex, Box } from '@rebass/grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import get from 'lodash.get';
import { Link, useParams } from '@reach/router';
import styled from 'styled-components';
import { CardContent } from '@material-ui/core';
import UserAvatar from '../components/atom/user-avatar';
import { useMutation } from 'react-query';
import { useUser } from '../lib/hooks/use-user';
import { logCheckIn } from '../lib/mutations/checkin';
import { useQueryClient } from 'react-query';
import useLocationCheck from '../lib/hooks/use-location-check';
import PlaceholderLoading from 'react-placeholder-loading';
import Close from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import { Divider } from '@material-ui/core';
import { useUserStats } from '../lib/hooks/use-user';
import { useDrawer } from '../lib/hooks/use-drawer';
import { LineChart } from './admin/components/line-chart';
import StatCard from './stat-card';

import Family from '@material-ui/icons/People';
import Profile from '@material-ui/icons/AccountCircle';
import Arrow from '@material-ui/icons/ArrowForwardIos';
import Verified from '@material-ui/icons/SignalCellularAlt';
import ArrowDrowdown from '@material-ui/icons/ArrowDropDown';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Cookies from 'js-cookie';
import { navigate } from '@reach/router';
import UserListItem from '../components/organisim/user-list-item';
import MobileSelector from './organisim/mobile-selector';
import OptionLink from './atom/options-link';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import firebase from 'firebase';

const DISTANCE_THRESHOLD = 0.1;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  font-size: 12px;
  padding: 16px;
  border-radius: 5px 5px;
  box-sizing: border-box;
`;

const LoadingWrappers = styled.div`
  witdh: auto;
  margin-bottom: 16px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const ChartControls = styled.div`
  font-size: 12px;
  color: ${({ selected }) => (selected ? '#fff' : '#a6a4a4')};
  font-weight: 600;
`;
const styles = {
  optionLink: {
    padding: '16px',
  },
  iconStyle: {
    color: '#03a9f4',
    fontSize: '30px',
  },
  statCard: {
    height: 'auto',
    padding: '8px 16px 8px 16px',
    background: 'transparent',
  },

  color: 'white',
  avatar: {
    margin: '8px',
    width: '100px',
    height: '100px',
    border: '4px solid #03a9f4',
  },
  button: {
    width: '100%',
    margin: '16px 0 8px',
  },
  chartControl: {
    width: 'auto',
    height: '35px',
    textTransform: 'none',
    background: '#ccc',
    color: '#fff',
    padding: '0',
  },
  listItem: {
    paddingTop: '6px',
    paddingBotom: '6px',
  },
  sectionPadding: {
    marginTop: '32px',
    padding: '0px 34px 0',
  },
};

const ProfileDetail = (props) => {
  const { classes } = props;
  const { userId } = useParams();
  const { user, loading } = useUser(userId);
  const { stats = {} } = useUserStats(userId);
  const { isDrawerOpen, toggleDrawer } = useDrawer();
  const [statCardData, setStatCardData] = useState('active_days');
  const [disableReason, setDisableReason] = useState('');
  const {
    isDrawerOpen: isDataTypeDrawerOpen,
    toggleDrawer: toggleDataTypeDrawer,
  } = useDrawer();

  const {
    isDrawerOpen: isProfileDrawerOpen,
    toggleDrawer: toggleProfileDrawer,
  } = useDrawer();
  const {
    isDrawerOpen: isChartDrawerOpen,
    toggleDrawer: toggleChartDrawer,
  } = useDrawer();

  const [activeChart, setActiveChart] = useState('Six week');
  const { distance, locating, error } = useLocationCheck(
    parseFloat(user.gym.latitude),
    parseFloat(user.gym.longitude),
    true
  );

  const queryClient = useQueryClient();
  const { mutate, isLoading, reset } = useMutation((data) => logCheckIn(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['user', userId]);
      queryClient.invalidateQueries(['user-stats', userId]);
    },
  });

  useEffect(() => {
    if (!Cookies.get('authToken')) {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (user?.disabled_user?.reason) {
      setDisableReason(user.disabled_user.reason); // Set the disable reason
    }
  }, [user?.disabledUser?.reason]);

  useEffect(() => {
    reset();
  }, [userId]);

  const leftBtn = () => {
    if (get(user, 'parent_id')) {
      return <Link to={`/profile/${user.parent.uid}`}>Back</Link>;
    } else {
      return null;
    }
  };

  const disableCheckin = () => {
    if (
      locating ||
      !distance ||
      (error && error.message === 'User denied Geolocation') ||
      distance > DISTANCE_THRESHOLD
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckin = async (user) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('buttonPushed');
    }
    if (disableCheckin()) {
      return;
    }

    mutate(user);
  };

  const statCountDataSet = {
    thisWeek: get(
      stats,
      statCardData === 'checkins'
        ? 'meta.total_weekly_checkins'
        : 'meta.total_weekly_active_days'
    ),
    sixWeek: get(
      stats,
      statCardData === 'checkins'
        ? 'meta.total_six_weeks_checkins'
        : 'meta.total_six_weeks_active_days'
    ),
    sixMonth: get(
      stats,
      statCardData === 'checkins'
        ? 'meta.total_six_months_checkins'
        : 'meta.total_six_months_active_days'
    ),
    lastPromotionCount: get(
      stats,
      statCardData === 'checkins'
        ? 'meta.checkins_count_since_last_promotion'
        : 'meta.active_days_since_last_promotion'
    ),
  };

  const statDataSet = {
    thisWeek: get(
      stats,
      statCardData === 'checkins' ? 'this_week_checkins' : 'this_week_checkins'
    ),
    sixWeek: get(
      stats,
      statCardData === 'checkins'
        ? 'last_six_weeks_data'
        : 'last_six_weeks_active_days'
    ),
    sixMonth: get(
      stats,
      statCardData === 'checkins'
        ? 'last_six_months_data'
        : 'last_six_months_active_days'
    ),
  };

  const navigateToChild = (childAccount) => {
    navigate(`/profile/${childAccount.uid}`);
    toggleDrawer();
  };

  const formatCheckIn = (checkins) => {
    if (!checkins) return [];
    return Object.entries(checkins).map(([day, count]) => ({
      x: day,
      y: count,
    }));
  };

  const getButtonText = (id) => {
    if (error && error.message) {
      if (error.message === 'User denied Geolocation') {
        return 'You must allow location services to check in';
      }
    } else if (locating) {
      return 'Finding Location...';
    } else if (distance > DISTANCE_THRESHOLD) {
      return `You're too far away to check in`;
    } else if (distance > 0 && distance < DISTANCE_THRESHOLD && !isLoading) {
      return '';
    } else if (!distance) {
      return 'Cant find location';
    } else if (isLoading) {
      return 'Checking In...';
    }
  };
  const logOut = async () => {
    try {
      await firebase.auth().signOut();
      Cookies.remove('authToken');
      navigate('/login');
    } catch (e) {
      console.log('oops something went wrong');
    }
  };

  const ButtonText = getButtonText(userId);

  const activeChartData = () => {
    switch (activeChart) {
      case 'This week':
        return statDataSet.thisWeek;
      case 'Six week':
        return statDataSet.sixWeek;
      case 'Six month':
        return statDataSet.sixMonth;
      default:
        return statDataSet.sixWeek;
    }
  };

  return (
    <PageLayout
      selected="help"
      pageTitle={`${user.parent_id ? 'Child Profile' : 'Profile'}`}
      leftButton={leftBtn()}
      noMobileMargin={true}
    >
      <Dialog
        open={!!disableReason}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h2 style={{ color: '#bf4747' }}>Account Disabled</h2>
            <Typography>{disableReason}</Typography>
          </DialogContentText>
          <Box mb="10px">
            <Button
              style={{
                color: '#bf4747',
                border: '1px solid #bf4747',
                width: '100%',
              }}
              variant="outlined"
              onClick={logOut}
            >
              Log Out
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Flex className={classes.sectionPadding} alignItems="center">
        {loading ? (
          <LoadingWrappers>
            <PlaceholderLoading
              shape="circle"
              width={100}
              height={100}
              colorStart="#3d3636"
              colorEnd="#303030"
            />
          </LoadingWrappers>
        ) : (
          <UserAvatar
            src={get(user, 'avatar_url')}
            name={get(user, 'name', '')}
          />
        )}
        <Box
          style={{
            height: '85px',
            boxSizing: 'border-box',
            marginLeft: '28px',
            marginTop: '8px',
          }}
        >
          <Typography variant="h5">
            {loading ? (
              <PlaceholderLoading
                shape="rect"
                width={200}
                height={25}
                colorStart="#3d3636"
                colorEnd="#303030"
              />
            ) : (
              get(user, 'name')
            )}
          </Typography>
          <Button
            className={classes.button}
            style={{
              height: '35px',
              marginTop: '12px',
            }}
            variant="outlined"
            color="primary"
            onClick={(e) => {
              handleCheckin(user);
            }}
            disabled={
              disableCheckin() ||
              !user.allow_checkin ||
              user.disabled_user?.reason
            }
          >
            {user.allow_checkin ? 'Check in' : 'Checked In'}
          </Button>
          <small>{ButtonText}</small>
        </Box>
      </Flex>

      <Box className={classes.sectionPadding}>
        <Box>
          <Flex mb="16px" alignItems="center" onClick={toggleDataTypeDrawer}>
            <Typography variant="h6" style={{ cursor: 'pointer' }}>
              {statCardData === 'checkins' ? 'Check Ins' : 'Active Days'}
            </Typography>
            <Box ml="4px">
              <ArrowDrowdown style={{ fontSize: '30px' }} />
            </Box>
          </Flex>
          <StatCard
            weekCount={statCountDataSet.thisWeek}
            sixWeekCount={statCountDataSet.sixWeek}
            monthCount={statCountDataSet.sixMonth}
            lastPromotionCount={statCountDataSet.lastPromotionCount}
          />
        </Box>
      </Box>
      <Flex
        className={classes.sectionPadding}
        mt="16px"
        justifyContent="flex-end"
        flexDirection="column"
      >
        <Flex justifyContent="flex-end">
          <Flex alignItems="center" onClick={toggleChartDrawer}>
            <ChartControls> {activeChart}</ChartControls>
            <ArrowDrowdown />
          </Flex>
        </Flex>
        <LineChart
          sectionKey={activeChart}
          firstDataSet={formatCheckIn(activeChartData())}
          maxCheckins={3}
        />
      </Flex>
      <Box className={classes.sectionPadding}>
        <Card>
          <List>
            {user.children.length > 0 && (
              <>
                <ListItem className={classes.listItem} onClick={toggleDrawer}>
                  <Flex width="100%" justifyContent="space-between">
                    <Flex>
                      <Box mr="16px">
                        <Family />
                      </Box>
                      <Typography variant="subtitle1">
                        Family accounts
                      </Typography>
                    </Flex>
                    <Box>
                      <Arrow />
                    </Box>
                  </Flex>
                </ListItem>
                <Divider />
              </>
            )}

            <ListItem
              className={classes.listItem}
              onClick={toggleProfileDrawer}
            >
              <Flex width="100%" justifyContent="space-between">
                <Flex>
                  <Box mr="16px">
                    <Profile />
                  </Box>
                  <Typography variant="subtitle1">Profile detail</Typography>
                </Flex>
                <Box>
                  <Arrow />
                </Box>
              </Flex>
            </ListItem>
            <Link to="edit">
              <Divider />
              <ListItem className={classes.listItem}>
                <Flex
                  width="100%"
                  marginTop="8px"
                  justifyContent="space-between"
                >
                  <Flex>
                    <Box mr="16px">
                      <EditIcon />
                    </Box>
                    <Typography>Edit Profile</Typography>
                  </Flex>
                  <Box>
                    <Arrow />
                  </Box>
                </Flex>
              </ListItem>
            </Link>
          </List>
        </Card>
      </Box>

      <SwipeableDrawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        onOpen={() => {}}
        PaperProps={{
          style: {
            borderRadius: '20px 20px 0 0',
            background: '#303030',
          },
        }}
        transitionDuration={{ enter: 300, exit: 250 }} // Adjust the animation duration (in milliseconds)
      >
        <>
          <Header>
            <IconWrapper onClick={toggleDrawer}>
              <Close />
            </IconWrapper>
            <Box>
              <Typography
                variant="subtitle1"
                style={{
                  marginRight: '10px',
                }}
              >
                Family Accounts
              </Typography>
            </Box>
            <div />
          </Header>
          <Box mt="16px">
            {user.children.map((childAccount) => (
              <Box key={childAccount.id} padding="0px 10px 0px 10px">
                <Divider />
                <UserListItem user={childAccount} handleClick={navigateToChild}>
                  <Button
                    className={classes.button}
                    color="primary"
                    variant="outlined"
                    disabled={disableCheckin() || !childAccount.allow_checkin}
                    onClick={() => handleCheckin(childAccount)}
                  >
                    {childAccount.allow_checkin ? 'Checkin' : 'Checked In'}
                  </Button>
                </UserListItem>
                <Divider />
              </Box>
            ))}
          </Box>
        </>
      </SwipeableDrawer>

      <SwipeableDrawer
        anchor="bottom"
        open={isProfileDrawerOpen}
        onClose={toggleProfileDrawer}
        onOpen={() => {}}
        PaperProps={{
          style: {
            borderRadius: '20px 20px 0 0',
            height: '30vh',
            background: '#303030',
          },
        }}
        transitionDuration={{ enter: 300, exit: 250 }} // Adjust the animation duration (in milliseconds)
      >
        <>
          <Header>
            <IconWrapper onClick={toggleProfileDrawer}>
              <Close />
            </IconWrapper>
            <Box>
              <Typography
                variant="subtitle1"
                style={{
                  marginRight: '10px',
                }}
              >
                Profile Details
              </Typography>
            </Box>
            <div />
          </Header>
          <Box>
            <CardContent>
              <Flex>
                <Box mr="8px">
                  <EmailIcon />
                </Box>
                <Typography variant="body1">
                  <strong>Email: </strong> {get(user, 'email' || '')}
                </Typography>
              </Flex>
              <Flex>
                <Box mr="8px">
                  <CalendarIcon />
                </Box>
                <Typography variant="body1">
                  <strong>DOB:</strong>
                  {get(user, 'dob' || '')}
                </Typography>
              </Flex>
              <Flex>
                <Box mr="8px">
                  <Verified />
                </Box>
                <Typography variant="body1">
                  <strong>Rank:</strong>
                  {get(user, 'belt.name' || '')}
                </Typography>
              </Flex>
              <Flex>
                <Typography variant="body1">
                  <strong>Description:</strong>
                  {get(user, 'description' || '')}
                </Typography>
              </Flex>
            </CardContent>
          </Box>
        </>
      </SwipeableDrawer>
      <MobileSelector
        title="Select Date Range"
        isDrawerOpen={isChartDrawerOpen}
        toggleDrawer={toggleChartDrawer}
      >
        <OptionLink
          label={'This week'}
          isSelected={activeChart === 'This week'}
          onClick={() => setActiveChart('This week')}
        />
        <OptionLink
          label={'Six week'}
          isSelected={activeChart === 'Six week'}
          onClick={() => setActiveChart('Six week')}
        />
        <OptionLink
          label={'Six month'}
          isSelected={activeChart === 'Six month'}
          onClick={() => setActiveChart('Six month')}
        />
      </MobileSelector>
      <MobileSelector
        title="Select Data Type"
        isDrawerOpen={isDataTypeDrawerOpen}
        toggleDrawer={toggleDataTypeDrawer}
      >
        <OptionLink
          label={'Check ins'}
          isSelected={statCardData === 'checkins'}
          onClick={() => setStatCardData('checkins')}
        />
        <OptionLink
          label={'Active Days'}
          isSelected={statCardData === 'active_days'}
          onClick={() => setStatCardData('active_days')}
        />
      </MobileSelector>
    </PageLayout>
  );
};

export default withStyles(styles)(ProfileDetail);
