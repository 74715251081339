import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import UserListItem from '../../organisim/user-list-item';
import Chip from '@material-ui/core/Chip';
import { monthFormat } from '../../../lib/utils/date-format';
import styled from 'styled-components';
import { Box, Flex } from '@rebass/grid';
import Profile from './profile';
import Drawer from '@material-ui/core/Drawer';
import Close from '@material-ui/icons/Close';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 50px;
  background: #03a9f4;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 1000;
  padding: 0 16px;
  box-sizing: border-box;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const ListItemContainer = styled.div`
  border-bottom: 1px solid #5a5858;
`;

const TableContainer = styled.div`
  height: 384px;
`;

export const UpcomingBirthdaysCard = ({ upcomingBirthdays }) => {
  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);
  const [activeMember, setActiveMember] = React.useState({});
  const toggleDrawer = () => {
    setisDrawerOpen(!isDrawerOpen);
  };
  const handleProfileClick = (user) => {
    setActiveMember(user);
    toggleDrawer();
  };
  return (
    <Card>
      <TableContainer>
        <CardContent>
          {upcomingBirthdays.length > 0 && (
            <>
              <Flex mb="30px" justifyContent="space-between">
                <Typography variant="h6">Birthdays</Typography>
                {/* {upcomingBirthdays.length > 4 && (
                  <Button variant="outlined" color="primary">
                    <Typography variant="body1">View All</Typography>
                  </Button>
                )} */}
              </Flex>
              <Flex justifyContent="space-between" p="16px">
                <Typography variant="body1">Name</Typography>
                <Typography variant="body1">Birthday</Typography>
              </Flex>
              <Divider />
              {upcomingBirthdays.map((user, i) => {
                if (i < 3) {
                  return (
                    <ListItemContainer key={`${i}-${user.name}`}>
                      <div onClick={() => handleProfileClick(user)}>
                        <UserListItem user={user}>
                          <Chip label={monthFormat(user.dob)} />
                        </UserListItem>
                      </div>
                    </ListItemContainer>
                  );
                } else return null;
              })}
            </>
          )}
          {upcomingBirthdays.length === 0 && (
            <Box>
              <Flex mb="30px" justifyContent="space-between">
                <Typography variant="h6">Birthdays</Typography>
              </Flex>
              <Divider />
              <Flex
                style={{
                  height: '200px',
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h6">No upcoming birthdays</Typography>
              </Flex>
            </Box>
          )}
        </CardContent>
      </TableContainer>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: { width: '100%', height: '100vh', background: '#303030' },
        }}
        transitionDuration={{ enter: 350, exit: 300 }} // Adjust the animation duration (in milliseconds)
      >
        <div>
          <Header>
            <IconWrapper onClick={toggleDrawer}>
              <Close />
            </IconWrapper>
            <Typography variant="h6">Member Profile</Typography>
            <div />
          </Header>
          <Profile user={activeMember} />
        </div>
      </Drawer>
    </Card>
  );
};

export default UpcomingBirthdaysCard;
