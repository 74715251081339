import React from 'react';
import { Avatar, withStyles } from '@material-ui/core';

const styles = {
  avatar: {
    width: '85px',
    height: '85px',
    border: '4px solid #03a9f4',
  },
};

export const UserAvatar = ({ src, name = '', classes }) => {
  return (
    <Avatar className={classes.avatar} src={src}>
      {name}
    </Avatar>
  );
};

export default withStyles(styles)(UserAvatar);
