import React from 'react';
import PageLayout from './page-layout';

export const PrivacyPolicy = (props) => {
  return (
    <PageLayout pageTitle="Privacy Policy">
      <div>
        <h1>Privacy Policy for Mat-time-tracker.com</h1>
        <p>
          <strong>Last Updated:</strong> 02/01/2024
        </p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to Mat-time-tracker.com. We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about our policy or our practices with regards
          to your personal information, please contact us at{' '}
          <a href="mailto:tomduffy1985@gmail.com">tomduffy1985@gmail.com</a>.
        </p>

        <h2>2. What Information Do We Collect?</h2>
        <p>
          We collect personal information that you voluntarily provide to us
          when registering at the Website, expressing an interest in obtaining
          information about us or our products and Services. The personal
          information that we collect depends on the context of your
          interactions with us and the Website, the choices you make, and the
          products and features you use.
        </p>

        <h2>3. How Do We Use Your Information?</h2>
        <p>
          We use personal information collected via our Website for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations.
        </p>

        <h2>4. Will Your Information Be Shared With Anyone?</h2>
        <p>
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations.
        </p>

        <h2>5. Do We Use Cookies and Other Tracking Technologies?</h2>
        <p>
          We may use cookies and other tracking technologies to collect and
          store your information.
        </p>

        <h2>6. How Do We Handle Your Social Logins?</h2>
        <p>
          If you choose to register or log in to our services using a social
          media account, we may have access to certain information about you.
        </p>

        <h2>7. What Is Our Stance on Third-party Websites?</h2>
        <p>
          We are not responsible for the safety of any information that you
          share with third-party providers who advertise, but are not affiliated
          with, our Website.
        </p>

        <h2>8. How Long Do We Keep Your Information?</h2>
        <p>
          We keep your information for as long as necessary to fulfill the
          purposes outlined in this privacy policy unless otherwise required by
          law.
        </p>

        <h2>9. How Do We Keep Your Information Safe?</h2>
        <p>
          We aim to protect your personal information through a system of
          organizational and technical security measures.
        </p>

        <h2>10. Do We Collect Information from Minors?</h2>
        <p>
          We do not knowingly collect data from or market to children under 18
          years of age.
        </p>

        <h2>11. What Are Your Privacy Rights?</h2>
        <p>You may review, change, or terminate your account at any time.</p>

        <h2>12. Controls for Do-Not-Track Features</h2>
        <p>
          Most web browsers and some mobile operating systems include a
          Do-Not-Track (“DNT”) feature or setting you can activate to signal
          your privacy preference not to have data about your online browsing
          activities monitored and collected.
        </p>

        <h2>13. Do California Residents Have Specific Privacy Rights?</h2>
        <p>
          Yes, if you are a resident of California, you are granted specific
          rights regarding access to your personal information.
        </p>

        <h2>14. Do We Make Updates to This Policy?</h2>
        <p>
          Yes, we will update this policy as necessary to stay compliant with
          relevant laws.
        </p>

        <h2>16. Image Capturing and Use of Avatar Images</h2>
        <h3>Collection of Images</h3>
        <p>
          At Mat-time-tracker.com, we respect and value the privacy of our
          users. As part of our service offerings, we provide the option for
          users to upload images to personalize their avatar on our platform.
          These images are voluntarily provided by the users and are used
          exclusively for the purpose of representing the user's visual identity
          on our website.
        </p>
        <h3>Use of Images</h3>
        <p>
          The images uploaded by users are used solely for the purpose of
          creating personalized avatar images to enhance the user experience on
          our website. These images help in creating a more engaging and
          personalized interaction among users. We do not use these images for
          any other purpose without obtaining further consent from the user.
        </p>
        <h3>Protection and Storage</h3>
        <p>
          We are committed to ensuring the security and protection of the images
          provided by our users. We implement a range of security measures to
          protect against unauthorized access, alteration, disclosure, or
          destruction of personal images. These images are stored securely and
          are accessible only by the user and authorized personnel of
          Mat-time-tracker.com.
        </p>
        <h3>Sharing and Disclosure</h3>
        <p>
          We do not share, sell, rent, or trade user images with third parties
          for their marketing purposes. The images may be disclosed to third
          parties only in the following circumstances:
        </p>
        <ul>
          <li>With your consent.</li>
          <li>To comply with legal obligations.</li>
          <li>
            To protect the rights and safety of our users and third parties, as
            well as our own.
          </li>
        </ul>
        <h3>Retention of Images</h3>
        <p>
          User images are retained for as long as the user's account remains
          active or as needed to provide services to the user. Users may remove
          or change their avatar image at any time via their account settings.
          Upon deletion of an account, we take steps to ensure that any personal
          images are also deleted in a timely manner.
        </p>
        <h3>Your Rights</h3>
        <p>
          Users have the right to access, update, or delete their personal
          images at any time. If you have any concerns about the use of your
          images, please contact us at{' '}
          <a href="mailto:tomduffy1985@gmail.com">tomduffy1985@gmail.com</a>.
        </p>

        <h2>15. How Can You Contact Us About This Policy?</h2>
        <p>
          If you have questions or comments about this policy, you may email us
          at <a href="mailto:tomduffy1985@gmail.com">tomduffy1985@gmail.com</a>{' '}
          or by post to your postal address.
        </p>
      </div>
    </PageLayout>
  );
};

export default PrivacyPolicy;
