import { useQuery } from 'react-query';
import { getDashboardData } from '../fetchers/dashboard';

export const useDashBoard = (gymId) => {
  const { data, isLoading, isError } = useQuery(
    [gymId],
    () => getDashboardData(gymId),
    {
      enabled: !!gymId,
    },
  );

  return {
    isError,
    isLoading,
    dashboardData: data,
  };
};
