import appConfig from '../../config/app';
import { getAuthHeaders } from '../fetchers/auth';

export async function logCheckIn(checkin) {
  const checkinId = checkin.id;
  const gymId = checkin.gymId || checkin.gym_id;
  const options = await getAuthHeaders();
  try {
    const res = await fetch(
      `${appConfig.baseApiUrl}/api/v1/users/${checkinId}/gyms/${gymId}/checkin`,
      {
        method: 'POST',
        headers: {
          ...options,
        },
      }
    );

    return await res.json();
  } catch (error) {
    console.log(error);
  }
}

export async function logBulkCheckins(checkins, gymId, userId) {
  const options = await getAuthHeaders();
  try {
    const res = await fetch(
      `${appConfig.baseApiUrl}/api/v1/users/${userId}/gyms/${gymId}/bulk_checkins`,
      {
        method: 'POST',
        headers: {
          ...options,
        },
        body: JSON.stringify({ checkins }),
      }
    );

    return await res.json();
  } catch (error) {
    console.log(error);
  }
}
