import React from 'react';
import PageLayout from './page-layout';
import { Flex, Box } from '@rebass/grid';

const helpTopics = [
  {
    title: 'How do I save the app to my home screen?',
    iphone_link: 'iphoneLink',
    android_link: 'androidLink',
  },
  {
    title: 'The location button says "Cant find location" ',
    iphone_link: 'iphoneLink',
    android_link: 'androidLink',
  },
  {
    title: 'How do I save the app to my home screen?',
    iphone_link: 'iphoneLink',
    android_link: 'androidLink',
  },
  {
    title: 'How do I save the app to my home screen?',
    iphone_link: 'iphoneLink',
    android_link: 'androidLink',
  },
];
const Help = () => {
  return (
    <PageLayout selected="help" pageTitle="Login" rightButton={() => {}}>
      <Flex
        justifyContent="left"
        alignItems="left"
        flexDirection="column"
        mb="16px"
      >
        <h2>Help Page</h2>
        {helpTopics.map((topic, i) => (
          <Box width="100%" mt="8px">
            <h4>
              {i + 1}. {topic.title}
            </h4>
            <Box>
              <strong>Iphone</strong>
            </Box>
            <a href={topic.iphone_link}>{topic.iphone_link}</a>
            <Box>
              <strong>Android</strong>
            </Box>
            <a href={topic.android_link}>{topic.android_link}</a>
          </Box>
        ))}
      </Flex>
    </PageLayout>
  );
};

export default Help;
