import React from 'react';
import { Box, Flex } from '@rebass/grid';
import { Divider, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { Link } from '@reach/router';

import Family from '@material-ui/icons/People';
import LinearProgress from '@material-ui/core/LinearProgress';
import Chip from '@material-ui/core/Chip';
import Edit from '@material-ui/icons/Edit';
import { CalendarToday } from '@material-ui/icons';
import { monthAndYearFormat } from '../../../lib/utils/date-format';

import styled from 'styled-components';

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  @media (max-width: 600px) {
    flex-direction: column;
    button {
      width: 100%;
      margin-top: 16px;
    }
  }
`;

export const PromotionInfo = ({ promo }) => {
  return (
    <Box>
      <CardHeader>
        <Typography variant="h5">{promo.name}</Typography>
        <Link to={`edit/${promo.id}`}>
          <Button variant="outlined" color="primary">
            <Flex>
              <Edit fontSize="small" />
              <Box ml="8px">
                <Typography variant="caption">Edit</Typography>
              </Box>
            </Flex>
          </Button>
        </Link>
      </CardHeader>
      <Divider />
      <Box mt="16px">
        <Typography variant="body1">{promo.description}</Typography>
      </Box>
      <Box>
        <Typography variant="caption">
          <strong>Created by:</strong> {promo?.user?.name}
        </Typography>
      </Box>
      <Box mb="16px">
        <Typography variant="caption">
          <strong>Active day threshold:</strong>{' '}
          {promo?.promotion_active_day_threshold}
        </Typography>
      </Box>
      <Box>
        <LinearProgress
          variant="determinate"
          value={promo?.promoted_users_percentage}
        />
        <Box mt="4px">
          <Typography variant="caption">
            <strong>Progress {promo?.promoted_users_percentage} % </strong>
          </Typography>
        </Box>
      </Box>
      <Box mt="16px" mb="16px" mr="8px">
        <Chip label={`${promo?.status}`} />
      </Box>
      <Divider />
      <Flex mt="8px" justifyContent="space-between">
        <Flex alignItems="center">
          <Box mr="10px">
            <CalendarToday fontSize="small" />
          </Box>
          <Typography variant="caption">
            <strong>Created:</strong> {monthAndYearFormat(promo?.created_at)}
          </Typography>
        </Flex>
        <Flex alignItems="center">
          <Box mr="10px">
            <Family fontSize="6px" />
          </Box>
          <Typography variant="caption">
            {' '}
            {promo?.user_promotions_count}
          </Typography>
        </Flex>
      </Flex>
    </Box>
  );
};

export default PromotionInfo;
