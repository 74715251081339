import React from 'react';
import { Flex } from '@rebass/grid';
import { ThreeDots } from 'react-loader-spinner';

export const LoadingDots = () => (
  <Flex
    style={{
      height: '200px',
    }}
    alignItems="center"
    justifyContent="center"
  >
    <ThreeDots
      visible={true}
      height="80"
      width="80"
      color="#03a9f4"
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  </Flex>
);
