import React, { useContext, useEffect, useState } from 'react';
import PageLayout from '../../page-layout';
import { useUser } from '../../../lib/hooks/use-user';
import { useDashBoard } from '../../../lib/hooks/use-dashboard';

import { AppContext } from '../../../app';
import { navigate, useParams } from '@reach/router';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import { Typography } from '@material-ui/core';
import PieCard from '../components/pie-card';
import { NewMembers } from '../components/new-members';
import DemographicsCard from '../components/demographics';
import UpcomingBirthdaysCard from '../components/birthdays';
import { CheckinChartCard } from '../components/checkin-chart';
import Drawer from '@material-ui/core/Drawer';
import { UserList } from '../components/user-list';
import { TrendsChart } from '../components/trends-chart';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { Link } from '@reach/router';
import { useIsMobile } from '../../../lib/hooks/use-is-mobile';
import { LoadingDots } from '../../atom/loading-dots';
// Original Color: #00A9E9
// Lighter Shade 1: #49C0F0
// Lighter Shade 2: #82D6F4
// Lighter Shade 3: #BCECF8
// Darker Shade 1: #008DC6
// Darker Shade 2: #0078A2
// Darker Shade 3: #005B80

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 50px;
  background: #03a9f4;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 16px;
  box-sizing: border-box;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const DashboardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-areas:
    'total-members active-members inactive-members'
    'recent-signups recent-signups signup-trend'
    'todays-checkins attendance-by-day checkin-trends'
    'demographics birthdays birthdays'
    'notifications notifications notifications';
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1200px) {
    grid-template-areas:
      'total-members total-members'
      'active-members inactive-members'
      'recent-signups recent-signups'
      'signup-trend checkin-trends'
      'attendance-by-day todays-checkins'
      'demographics birthdays'
      'notifications notifications';
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 820px) {
    grid-template-areas:
      'total-members total-members'
      'active-members inactive-members'
      'recent-signups recent-signups'
      'signup-trend signup-trend'
      'todays-checkins todays-checkins'
      'attendance-by-day attendance-by-day'
      'checkin-trends checkin-trends'
      'demographics demographics'
      'birthdays birthdays'
      'notifications notifications';
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-areas:
      'total-members'
      'active-members'
      'inactive-members'
      'recent-signups'
      'signup-trend'
      'checkin-trends'
      'todays-checkins'
      'attendance-by-day'
      'demographics'
      'birthdays'
      'notifications';
    grid-template-columns: 1fr;
  }
`;

const AreaContainer = styled.div`
  grid-area: ${(props) => props.area};
`;

const dateStampGenerator = (dateObj) => {
  // Extract the year, month, and day
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so add 1
  const day = String(dateObj.getDate()).padStart(2, '0');

  // Create the date stamp in the format YYYY-MM-DD
  const dateStamp = `${year}-${month}-${day}`;
  return dateStamp;
};

const getThisWeeksDates = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const startOfWeek = new Date(today);
  const endOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek);
  endOfWeek.setDate(today.getDate() + (6 - dayOfWeek));
  return {
    startOfWeek: dateStampGenerator(startOfWeek),
    endOfWeek: dateStampGenerator(endOfWeek),
  };
};

const getTomorrow = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return dateStampGenerator(tomorrow);
};

const dashBoardQueryBuilder = (name) => {
  switch (name) {
    case 'total-members':
      return 'total_members';
    case 'active-members':
      return {
        status: 'active',
      };
    case 'inactive-members':
      return {
        status: 'inactive',
      };
    case 'recent-signups':
      return 'recent_signups_count';
    case 'todays-checkins':
      return {
        startDate: dateStampGenerator(new Date()),
        endDate: getTomorrow(),
      };
    case 'attendance-by-day':
      return {
        startDate: getThisWeeksDates().startOfWeek,
        endDate: getThisWeeksDates().endOfWeek,
      };
    default:
      return {};
  }
};

export const GymReports = () => {
  const { loggedInUserId } = useContext(AppContext);
  const { user = {}, isAdmin, loading } = useUser(loggedInUserId);
  const { gymUid } = useParams();
  const { dashboardData = {} } = useDashBoard(gymUid);
  const { recent_signups = [] } = dashboardData;
  const { upcoming_birthdays = [] } = dashboardData;
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [activeDrawer, setActiveDrawer] = useState('');
  const [queryParams, setQueryParams] = useState({});
  const { isMobile } = useIsMobile();

  const formatCheckIn = (checkins) => {
    if (!checkins) return [];
    return Object.entries(checkins).map(([day, count]) => ({
      x: day,
      y: count,
    }));
  };

  const formatTodaysCheckInData = (checkins) => {
    if (!checkins) return [];
    return Object.entries(checkins).map(([time, count]) => ({
      x: time,
      y: count,
    }));
  };

  useEffect(() => {
    if (user.id && !isAdmin) {
      navigate(`/profile/${user.uid}`);
    }
  }, [user]);

  const toggleDrawer = (section) => {
    const sectionName = section || '';
    setActiveDrawer(sectionName);
    setisDrawerOpen(!isDrawerOpen);
  };

  const handleFilter = (filter) => {
    setQueryParams(dashBoardQueryBuilder(filter));
  };

  const { signup_trends_by_age = {} } = dashboardData;
  return (
    <>
      <PageLayout selected="reports" pageTitle={`${'Dashboard'}`} dt>
        {loading || !user.id ? (
          <LoadingDots />
        ) : (
          <div>
            <Box mb="50px" mt={4}>
              <Typography variant="h5">Hello, {user.name}</Typography>
              <Typography variant="subtitle1">
                Here is your <strong> {user.gym.name} </strong> gym dashboard
              </Typography>
            </Box>
            <DashboardContainer>
              <AreaContainer area="total-members">
                <Link to={`/admin/gyms/${gymUid}/users`}>
                  <PieCard
                    greyValue={0}
                    mainValue={dashboardData.total_members}
                    title="Total"
                    color="#00A9E9"
                  />
                </Link>
              </AreaContainer>
              <AreaContainer area="active-members">
                <div
                  onClick={() => {
                    handleFilter('active-members');
                    toggleDrawer('Active members');
                  }}
                >
                  <PieCard
                    mainValue={dashboardData.active_members_count}
                    greyValue={dashboardData.inactive_members_count}
                    title="Active"
                    color="#00E9A9"
                  />
                </div>
              </AreaContainer>
              <AreaContainer area="inactive-members">
                <div
                  onClick={() => {
                    handleFilter('inactive-members');
                    toggleDrawer('In active members');
                  }}
                >
                  <PieCard
                    mainValue={dashboardData.inactive_members_count}
                    greyValue={dashboardData.active_members_count}
                    title="Inactive"
                    color="#E9A900"
                  />
                </div>
              </AreaContainer>
              <AreaContainer area="recent-signups">
                <NewMembers
                  signupCount={dashboardData.recent_signups_count}
                  newMembers={recent_signups}
                />
              </AreaContainer>
              <AreaContainer area="signup-trend">
                <TrendsChart
                  title="Sign up trends"
                  dataSet={formatCheckIn(
                    signup_trends_by_age.total_signups_last_six_months
                  )}
                  secondDataSet={formatCheckIn(
                    signup_trends_by_age.signup_counts_adults_last_six_months
                  )}
                  thirdDataSet={formatCheckIn(
                    signup_trends_by_age.signup_counts_kids_last_six_months
                  )}
                  labelConfig={{
                    label1: 'Total',
                    label2: 'Adults',
                    label3: 'Kids',
                  }}
                />
              </AreaContainer>
              <AreaContainer area="birthdays">
                <UpcomingBirthdaysCard upcomingBirthdays={upcoming_birthdays} />
              </AreaContainer>
              <AreaContainer area="todays-checkins">
                <div
                  onClick={() => {
                    handleFilter('todays-checkins');
                    toggleDrawer('Todays checkins');
                  }}
                >
                  <CheckinChartCard
                    showDetailsButton={true}
                    checkinTrendsData={formatTodaysCheckInData(
                      dashboardData.todays_adults_checkins_by_time
                    )}
                    secondDataSet={formatTodaysCheckInData(
                      dashboardData.todays_kids_checkins_by_time
                    )}
                    labelConfig={{
                      label1: 'Adults',
                      label2: 'Kids',
                    }}
                    title="Today's checkins"
                  />
                </div>
              </AreaContainer>

              <AreaContainer area="attendance-by-day">
                <div
                  onClick={() => {
                    handleFilter('attendance-by-day');
                    toggleDrawer('This weeks checkins');
                  }}
                >
                  <CheckinChartCard
                    showDetailsButton={true}
                    title="This weeks attendance"
                    checkinTrendsData={formatCheckIn(
                      dashboardData.checkin_counts_by_day_adults
                    )}
                    secondDataSet={formatCheckIn(
                      dashboardData.checkin_counts_by_day_kids
                    )}
                    labelConfig={{
                      label1: 'Adults',
                      label2: 'Kids',
                    }}
                  />
                </div>
              </AreaContainer>
              <AreaContainer area="demographics">
                <DemographicsCard
                  kidsCount={dashboardData.kids_count}
                  adultsCount={dashboardData.adults_count}
                />
              </AreaContainer>
              <AreaContainer area="checkin-trends">
                <TrendsChart
                  title="Checkin trends"
                  dataSet={formatCheckIn(
                    dashboardData.checkin_counts_last_six_months
                  )}
                  secondDataSet={formatCheckIn(
                    dashboardData.checkin_counts_adults_last_six_months
                  )}
                  thirdDataSet={formatCheckIn(
                    dashboardData.checkin_counts_kids_last_six_months
                  )}
                  labelConfig={{
                    label1: 'Total',
                    label2: 'Adults',
                    label3: 'Kids',
                  }}
                />
              </AreaContainer>
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={() => toggleDrawer('')}
                BackdropProps={{ invisible: isMobile ? true : false }}
                PaperProps={{
                  style: {
                    width: isMobile ? '100%' : '60%',
                    background: '#303030',
                  },
                }}
                transitionDuration={{ enter: 350, exit: 300 }} // Adjust the animation duration (in milliseconds)
              >
                <div>
                  <Header>
                    <IconWrapper onClick={() => toggleDrawer('')}>
                      <ArrowBack />
                    </IconWrapper>
                    <Typography variant="h6">
                      {activeDrawer || <LoadingDots />}
                    </Typography>
                    <div />
                  </Header>
                  <Box p="24px">
                    <Box mb="42px">
                      <Typography variant="h5">
                        {activeDrawer || <LoadingDots />}
                      </Typography>
                    </Box>
                    <UserList
                      onClose={toggleDrawer}
                      gymUid={gymUid}
                      filters={queryParams}
                    />
                  </Box>
                </div>
              </Drawer>
            </DashboardContainer>
          </div>
        )}
      </PageLayout>
    </>
  );
};

export default GymReports;
