import React, { Fragment } from 'react';
import styled from 'styled-components';

const ModalWrapper = styled.div`
  width: 90%;
  max-width: 500px;
  height: auto;
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalOverlay = styled.div`
  width: 100%;
  height: 1000px;
  background-color: black;
  opacity: 0.6;
  position: fixed;
  top: 50%,
  left: 50%,
  z-index: 1000;
`;

export const Modal = ({ isOpen, children, onClose }) => {
  return (
    <Fragment>
      {isOpen ? (
        <Fragment>
          <ModalWrapper>{children}</ModalWrapper>
          <ModalOverlay onClick={onClose} />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default Modal;
