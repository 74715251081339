import appConfig from '../../config/app';
import { getAuthHeaders } from './auth';

export function getGym(gymUUID) {
  return fetch(`${appConfig.baseApiUrl}/api/v1/gyms/${gymUUID}`)
    .then((response) => response.json())
    .catch((error) => console.error(error));
}

export function getGymByInvite(inviteCode) {
  return fetch(
    `${appConfig.baseApiUrl}/api/v1/gyms/show_by_invite_code/${inviteCode}`
  )
    .then((response) => response.json())
    .catch((error) => console.error(error));
}

export async function createGym(gymData) {
  const options = await getAuthHeaders();
  return fetch(`${appConfig.baseApiUrl}/api/v1/gyms`, {
    method: 'POST',
    headers: {
      ...options,
    },
    body: JSON.stringify({
      ...gymData,
    }),
  })
    .then((response) => response.json())
    .then((data) => data.name)
    .catch((error) => console.error(error));
}

function buildAgeQuery(age) {
  switch (age) {
    case 'adult':
      return '&is_adult=true';
    case 'child':
      return '&is_adult=false';
    default:
      return '';
  }
}

export async function fetchGymUsers(gymUid, searchTerm, params) {
  const options = await getAuthHeaders();
  const {
    age,
    program,
    status,
    startDate,
    endDate,
    currentPage,
    perPage = 50,
    sortBy,
    sortOrder,
    queryString,
    csv,
    lastPromoted,
  } = params;

  const searchQuery = searchTerm ? `&search_query=${searchTerm}` : '';
  const enabledDateQueryString =
    endDate && startDate ? `&start_date=${startDate}&end_date=${endDate}` : '';
  const dateQueryString = !lastPromoted ? enabledDateQueryString : '';

  const page = currentPage ? `&page=${currentPage}` : '';
  const perPageQuery = perPage ? `&per_page=${perPage}` : '';
  const programQuery = program ? `&gym_program_id=${program}` : '';
  const ageQuery = age ? buildAgeQuery(age) : '';
  const memberStatus = status ? `&status=${status}` : '';
  const sortByQuery = sortBy ? `sort_by=${sortBy}` : 'sort_by=name';
  const sortOrderQuery = sortBy ? `&sort_order=${sortOrder}` : '';
  const csvQuery = csv ? `&format=csv` : '';
  const lastPromotedQuery = lastPromoted
    ? `&filter_active_days_since_last_promotion=${lastPromoted}`
    : '';
  const dynamicQueryString = `${sortByQuery}${sortOrderQuery}${searchQuery}${dateQueryString}${page}${perPageQuery}${programQuery}${ageQuery}${memberStatus}${csvQuery}${lastPromotedQuery}`;
  // This is to check for a query string override
  const finalParamQueryString = queryString
    ? `&${queryString}`
    : dynamicQueryString;

  if (gymUid) {
    try {
      const users = await fetch(
        `${appConfig.baseApiUrl}/api/v1/gyms/${gymUid}/users?${finalParamQueryString}`,
        {
          headers: {
            ...options,
          },
        }
      );

      if (csv) {
        return users;
      }

      const usersRes = await users.json();
      return usersRes;
    } catch (error) {
      console.log(error);
    }
  } else {
    return { error: 'User not authenticated' };
  }
}
