import { useState } from 'react';
import { format, subDays } from 'date-fns';

export const useSearchFilters = (initialFilters = {}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(
    format(subDays(new Date(), 90), 'YYYY-MM-DD')
  );

  const [endDate, setEndDate] = useState(format(Date.now(), 'YYYY-MM-DD'));
  const [age, setAge] = useState('');
  const [program, setProgram] = useState(null);
  const [lastPromoted, setLastPromoted] = useState(true);

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const handleProgramChange = (event) => {
    setProgram(event.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(format(e.target.value, 'YYYY-MM-DD'));
  };

  const handleStartDateChange = (e) => {
    setStartDate(format(e.target.value, 'YYYY-MM-DD'));
  };
  return {
    handleProgramChange,
    program,
    searchTerm,
    setSearchTerm,
    setLastPromoted,
    lastPromoted,
    startDate,
    endDate,
    age,
    handleAgeChange,
    handleEndDateChange,
    handleStartDateChange,
  };
};
