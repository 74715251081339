import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchDisabledUsers } from '../fetchers/users';

export function useDisabledUsers(gym_id) {
  const { data, isLoading, error } = useQuery(
    [`disabled-users`, gym_id],
    () => fetchDisabledUsers(gym_id),
    {
      refetchOnWindowFocus: true,
      enabled: !!gym_id,
    },
  );

  const [disabledUsers, setDisabledUsers] = useState([]);

  useEffect(() => {
    const setUsersData = (data) => {
      setDisabledUsers(data);
    };
    setUsersData(data);
  }, [data]);

  return {
    disabledUsers,
    setDisabledUsers: setDisabledUsers,
    loading: isLoading,
    error,
  };
}
