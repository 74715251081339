import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({
  title,
  message,
  open,
  handleCancel,
  handleAgree,
  buttonAgreeText = 'Agree',
  buttonCancelText = 'Cancel',
}) {
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            {buttonCancelText}
          </Button>
          <Button variant="outlined" onClick={handleAgree} autoFocus>
            {buttonAgreeText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
