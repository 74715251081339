import React from 'react';
import { Card, CardContent, Typography, Divider, Box } from '@material-ui/core';
import UserListItem from '../../organisim/user-list-item';
import Chip from '@material-ui/core/Chip';
import { monthFormat } from '../../../lib/utils/date-format';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import Profile from './profile';
import Drawer from '@material-ui/core/Drawer';
import Close from '@material-ui/icons/Close';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 50px;
  background: #03a9f4;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 16px;
  box-sizing: border-box;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const ListItemContainer = styled.div`
  border-bottom: 1px solid #5a5858;
`;

const TableContainer = styled.div`
  height: 384px;
`;

export const NewMembers = ({ newMembers, signupCount }) => {
  const [isDrawerOpen, setisDrawerOpen] = React.useState(false);
  const [activeMember, setActiveMember] = React.useState({});
  const toggleDrawer = () => {
    setisDrawerOpen(!isDrawerOpen);
  };
  const handleProfileClick = (user) => {
    setActiveMember(user);
    toggleDrawer();
  };

  return (
    <Card>
      <TableContainer>
        <CardContent>
          <Box>
            <Flex mb="30px" justifyContent="space-between">
              <Typography variant="h6">{signupCount} New sign ups</Typography>
              {/* {signupCount > 3 && (
                <Button variant="outlined" color="primary">
                  <Typography variant="body1">View All</Typography>
                </Button>
              )} */}
            </Flex>
            <Flex justifyContent="space-between" p="16px">
              <Typography variant="body1">Name</Typography>
              <Typography variant="body1">Sign up date</Typography>
            </Flex>
          </Box>
          <Divider />
          {newMembers.map((user, i) => {
            if (i < 3) {
              return (
                <ListItemContainer key={`${i}-${user.name}`}>
                  <div onClick={() => handleProfileClick(user)}>
                    <UserListItem user={user}>
                      <Chip label={monthFormat(user.created_at)} />
                    </UserListItem>
                  </div>
                </ListItemContainer>
              );
            } else return null;
          })}
        </CardContent>
      </TableContainer>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        BackdropProps={{ invisible: true }}
        PaperProps={{
          style: { width: '100%', height: '100vh', background: '#303030' },
        }}
        transitionDuration={{ enter: 350, exit: 300 }} // Adjust the animation duration (in milliseconds)
      >
        <div>
          <Header>
            <IconWrapper onClick={toggleDrawer}>
              <Close />
            </IconWrapper>
            <Typography variant="h6">Member Profile</Typography>
            <div />
          </Header>
          <Profile user={activeMember} />
        </div>
      </Drawer>
    </Card>
  );
};
