import React, { useEffect } from 'react';
import { withStyles, Avatar } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Box, Flex } from '@rebass/grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import User from '@material-ui/icons/Person';
import Loading from '@material-ui/core/CircularProgress';
import SearchBelts from '../atom/search';
import FormControl from '@material-ui/core/FormControl';
import { format } from 'date-fns';
import { get } from 'lodash';
import styled from 'styled-components';

const styles = {
  textField: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  avatar: {
    margin: '8px',
    width: '100px',
    height: '100px',
    border: '4px solid #03a9f4',
  },
  inputFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    position: 'absolute',
    zIndex: '-1',
  },
};

const AddPhotoButton = styled.div`
  border: 1px solid #03a9f4;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  width: 200px;
  justify-content: center;
  display: flex;
  margin: 16px auto 16px;
  color: #03a9f4;
  align-items: center;
`;

const ProfileForm = ({
  classes = {},
  user = {},
  beltSizes = [],
  gymPrograms = [],
  updateTextField = () => {},
  updateSelectField = () => {},
  uploadImage = () => {},
  isImageUploading = false,
  submitForm = () => {},
  isUpdatingUser = false,
  updateErrors = false,
  setFormIsDirty = () => {},
  signup = false,
}) => {
  useEffect(() => {
    // This is kicking the form to rerender to update the combined
    // user object, otherwise the form is missing fields
    // DO NOT REMOVE unless you have a better solution
    updateTextField({ target: { value: user.name } }, 'name');
  }, []);
  return (
    <React.Fragment>
      <Flex justifyContent="center">
        <Avatar
          className={classes.avatar}
          sizes="200px"
          src={`${user.avatar_url || ''}`}
        >
          {isImageUploading ? <Loading /> : <User fontSize="large" />}
        </Avatar>
      </Flex>
      <Flex justifyContent="center">
        <label htmlFor="avatar">
          <AddPhotoButton>
            {get(user, 'avatar_url') ? 'Update Photo' : 'Add Photo'}
          </AddPhotoButton>
          <input
            className={classes.inputFile}
            type="file"
            id="avatar"
            onChange={uploadImage}
          />
        </label>
      </Flex>
      {get(user, 'errors', []).includes('avatar_url') && (
        <Flex justifyContent="center">
          <span style={{ color: 'red' }}>You must add a photo</span>
        </Flex>
      )}
      <Box mt="16px">
        <Card>
          <CardContent>
            <Box mt="16px">
              <TextField
                className={classes.textField}
                value={user.name}
                onChange={(e) => {
                  updateTextField(e, 'name');
                  setFormIsDirty(true);
                }}
                label="Name"
                error={get(user, 'errors', []).includes('name')}
              />
            </Box>
            <Box mt="16px">
              <TextField
                className={classes.textField}
                value={user.email}
                onChange={(e) => {
                  updateTextField(e, 'email');
                  setFormIsDirty(true);
                }}
                label="Email"
                disabled
              />
            </Box>
            <Box mt="16px">
              <TextField
                className={classes.textField}
                value={get(user, 'gym.name') || ''}
                onChange={(e) => updateTextField(e, 'gym_name')}
                label="Gym Location"
                disabled
              />
            </Box>
            <Box mt="16px">
              <TextField
                className={classes.textField}
                type="date"
                value={user.jits_start_date || format(Date.now(), 'YYYY-MM-DD')}
                onChange={(e) => {
                  updateTextField(e, 'jits_start_date');
                  setFormIsDirty(true);
                }}
                label="Jiu Jitsu Start Date"
                error={get(user, 'errors', []).includes('jits_start_date')}
              />
            </Box>
            <Box mt="16px">
              <TextField
                className={classes.textField}
                type="date"
                value={user.start_date || format(Date.now(), 'YYYY-MM-DD')}
                onChange={(e) => {
                  updateTextField(e, 'start_date');
                  setFormIsDirty(true);
                }}
                label="Gym Start Date"
                error={get(user, 'errors', []).includes('start_date')}
              />
            </Box>
            <Box mt="16px">
              <TextField
                className={classes.textField}
                type="date"
                value={user.dob || format(Date.now(), 'YYYY-MM-DD')}
                onChange={(e) => {
                  updateTextField(e, 'dob');
                  setFormIsDirty(true);
                }}
                label="DOB"
                error={get(user, 'errors', []).includes('dob')}
              />
            </Box>
            <Box mt="16px">
              <SearchBelts
                gymId={get(user, 'gym.id')}
                className={classes}
                onChange={updateSelectField}
                setFormIsDirty={setFormIsDirty}
                value={user.belt}
                error={get(user, 'errors', []).includes('belt')}
              />
            </Box>
            <Box mt="16px">
              <FormControl className={classes.textField}>
                <InputLabel htmlFor="select-multiple-chip">
                  Belt Size
                </InputLabel>
                <Select
                  value={user.belt_size}
                  onChange={(e) => {
                    updateSelectField(e, 'belt_size');
                    setFormIsDirty(true);
                  }}
                  error={get(user, 'errors', []).includes('belt_size')}
                >
                  <MenuItem value={user.belt_size}>
                    {get(user, 'belt_size.name') || 'Select Belt Size'}
                  </MenuItem>
                  {beltSizes.map((size) => (
                    <MenuItem key={size.id} value={size}>
                      {size.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt="16px">
              <FormControl className={classes.textField}>
                <InputLabel>Gym Program</InputLabel>
                <Select
                  value={user?.gym_program}
                  onChange={(e) => {
                    updateSelectField(e, 'gym_program');
                    setFormIsDirty(true);
                  }}
                >
                  <MenuItem value={user?.gym_program}>
                    {get(user, 'gym_program.name') || 'Select Gym Program'}
                  </MenuItem>
                  {gymPrograms.map((program) => (
                    <MenuItem key={program.id} value={program}>
                      {program.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt="16px">
              <TextField
                className={classes.textField}
                value={user.description}
                onChange={(e) => {
                  updateTextField(e, 'description');
                  setFormIsDirty(true);
                }}
                label="Jiu Jitsu Bio"
                rows="5"
                rowsMax="10"
                error={get(user, 'errors', []).includes('description')}
                multiline
              />
            </Box>
          </CardContent>
        </Card>
        <Box mt="16px">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="large"
            onClick={() => submitForm(user)}
            disabled={isUpdatingUser}
          >
            {isUpdatingUser ? (
              <Loading size="20px" color="secondary" />
            ) : (
              <span>Submit {signup ? 'profile' : 'changes'}</span>
            )}
          </Button>

          {get(user, 'errors', []).length > 0 && (
            <Flex justifyContent="center">
              <span style={{ color: 'red', marginTop: '16px' }}>
                You must complete the form
              </span>
            </Flex>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default withStyles(styles)(ProfileForm);
