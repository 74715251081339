import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useQuery } from 'react-query';
import config from '../../config/app';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default function SearchBelts({
  className,
  onChange,
  value,
  gymId,
  label,
  setFormIsDirty = () => {},
  error = false,
}) {
  const [inputValue, setInputValue] = useState('White');
  const [options, setOptions] = useState([{ name: 'Search for Belt' }]);
  const debouncedInputValue = useDebounce(inputValue, 500);
  const { data = [] } = useQuery(
    `belt-search-${debouncedInputValue}`,
    () => {
      return fetch(
        `${config.baseApiUrl}/api/v1/gyms/${gymId}/belts?search_term=${inputValue}`
      ).then((res) => res.json());
    },
    {
      enabled: inputValue.length > 0,
    }
  );

  useEffect(() => {
    if (data.length > 0) {
      setOptions(data);
    }
  }, [data]);

  const customStyles = {
    color: 'white', // Set the text color to white
  };

  return (
    <Autocomplete
      onChange={(event, newValue) => {
        onChange({ target: { value: newValue } }, 'belt');
        setFormIsDirty(true);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      defaultValue={value}
      getOptionLabel={(option) => option.name || value.name || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label ? label : 'Belt'}
          placeholder="Search for Belt"
          InputProps={{
            ...params.InputProps,
            style: {
              ...customStyles,
              borderBottom: '1px solid white',
            },
          }}
          InputLabelProps={{
            style: customStyles, // Apply custom styles to the input label
          }}
          error={!!error}
        />
      )}
    />
  );
}
