import appConfig from '../../config/app';
import { getAuthHeaders } from './auth';

export async function fetchUserCheckins(user_id) {
  const options = await getAuthHeaders();
  return fetch(`${appConfig.baseApiUrl}/api/v1/users/${user_id}/checkins`, {
    headers: {
      ...options,
    },
  })
    .then((data) => data.json())
    .then((data) => data)
    .catch((error) => error);
}
