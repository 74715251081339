import React from 'react';
import { Card, CardContent, Typography, Box } from '@material-ui/core';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
} from 'victory';

import { ChartContainer, ChartOverlay } from './styles';

const LabelBox = styled.div`
  width: 10px;
  height: 10px;
  background: ${(props) => props.color};
  margin-right: 10px;
  border: 1px solid white;
`;

const BoxLabelFont = styled.div`
  font-size: 12px;
`;

export const TrendsChart = ({
  dataSet,
  secondDataSet,
  thirdDataSet,
  title,
  labelConfig,
}) => {
  const upperAxisBound = Math.max(...dataSet.map((item) => item.y), 10);
  const { label1, label2, label3 } = labelConfig;
  return (
    <Card>
      <CardContent>
        <Box>
          <Typography variant="h6">{title}</Typography>
        </Box>
      </CardContent>
      <ChartContainer>
        <ChartOverlay />
        <Flex pl="20px">
          <Flex mr="10px">
            <LabelBox color="#00A9E9" />
            <BoxLabelFont>{label1}</BoxLabelFont>
          </Flex>
          {label2 && (
            <Flex mr="10px">
              <LabelBox color="#A900E9" />
              <BoxLabelFont>{label2}</BoxLabelFont>
            </Flex>
          )}
          {label3 && (
            <Flex>
              <LabelBox color="#00E9A9" />
              <BoxLabelFont>{label3}</BoxLabelFont>
            </Flex>
          )}
        </Flex>
        <VictoryChart domainPadding={10}>
          <VictoryLine
            animate={{
              duration: 1500,
              onLoad: { duration: 500 },
            }}
            data={dataSet}
            style={{
              data: {
                stroke: '#00A9E9',
                strokeWidth: 2,
                strokeLinecap: 'round',
              },
            }}
          />
          <VictoryScatter
            data={dataSet}
            style={{
              data: { fill: '#00A9E9' }, // Color of the data points
            }}
          />

          {secondDataSet && (
            <VictoryLine
              data={secondDataSet}
              style={{
                data: {
                  strokeDasharray: '4, 4',
                  stroke: '#A900E9',
                  strokeWidth: 2,
                  strokeLinecap: 'round',
                },
              }}
            />
          )}

          {secondDataSet && (
            <VictoryScatter
              data={secondDataSet}
              style={{
                data: { fill: '#A900E9' }, // Color of the data points
              }}
            />
          )}
          {thirdDataSet && (
            <VictoryLine
              animate={{
                duration: 1500,
                onLoad: { duration: 500 },
              }}
              data={thirdDataSet}
              style={{
                data: {
                  strokeDasharray: '4, 4',
                  stroke: '#00E9A9',
                  strokeWidth: 2,
                  strokeLinecap: 'round',
                },
              }}
            />
          )}
          {thirdDataSet && (
            <VictoryScatter
              data={thirdDataSet}
              style={{
                data: { fill: '#00E9A9' }, // Color of the data points
              }}
            />
          )}
          <VictoryAxis
            style={{
              axis: {
                stroke: 'white', //CHANGE COLOR OF X-AXIS
              },
              tickLabels: {
                fill: 'white', //CHANGE COLOR OF X-AXIS LABELS
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: {
                stroke: 'white', // CHANGE COLOR OF Y-AXIS
              },
              tickLabels: {
                fill: 'white', // CHANGE COLOR OF Y-AXIS LABELS
              },
              grid: {
                stroke: 'gray', // Color of the grid lines
                strokeDasharray: '2, 2', // Dash pattern for the grid lines
              },
            }}
          />
          <VictoryAxis
            dependentAxis // This specifies that this axis is for the Y-axis
            domain={[0, upperAxisBound]}
            style={{
              axis: {
                stroke: 'white', //CHANGE COLOR OF X-AXIS
              },
              tickLabels: {
                fill: 'white', //CHANGE COLOR OF X-AXIS LABELS
              },
            }}
          />
        </VictoryChart>
      </ChartContainer>
    </Card>
  );
};
