import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Button,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import PageLayout from '../../page-layout';
import { navigate, useParams } from '@reach/router';
import { createPromo } from '../../../lib/mutations/create-promotion';
import { useUser } from '../../../lib/hooks/use-user';
import { Flex } from '@rebass/grid';
import { Add, ArrowBack } from '@material-ui/icons';
import { AppContext } from '../../../app';
import styled from 'styled-components';

const InviteesContainer = styled.div`
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}`;

const styles = {
  textField: {
    width: '100%',
  },
  button: {
    width: '100%',
    margin: '16px 0 8px',
  },
  formControl: {
    margin: '16px 0',
    width: '100%',
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
};

const CreatePromotion = ({ classes }) => {
  const { loggedInUserId } = useContext(AppContext);
  const { gymUid } = useParams();
  const { user } = useUser(loggedInUserId);

  const [formErrors, setFormErrors] = useState({});

  const [promotionDetails, setPromotionDetails] = useState({
    name: '',
    description: '',
    promotion_active_day_threshold: 30,
    status: 0,
  });

  useEffect(() => {
    if (user && user.id && promotionDetails.createdBy !== user.id) {
      setPromotionDetails((prevDetails) => ({
        ...prevDetails,
        createdBy: user.id,
        gymId: user.gym_id,
      }));
    }
  }, [user, promotionDetails.createdBy, promotionDetails.gymId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPromotionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (promotionDetails.name === '' || promotionDetails.description === '') {
      setFormErrors({
        ...formErrors,
        name: promotionDetails.name === '' ? 'Name is required' : '',
        description:
          promotionDetails.description === '' ? 'Description is required' : '',
        activeDayThreshold:
          promotionDetails.promotion_active_day_threshold === ''
            ? 'Promotion Active Day Threshold is required'
            : '',
      });

      return {
        error:
          promotionDetails.name === '' ||
          promotionDetails.description === '' ||
          promotionDetails.promotion_active_day_threshold === '',
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const error = validateForm();

    if (error) {
      return;
    }

    try {
      const promo = await createPromo(promotionDetails);
      if (promo.id) {
        navigate(`/admin/gyms/${gymUid}/promotions`);
      }
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const handleInitialPromoSaveOrToggle = async () => {
    const error = validateForm();

    if (error) {
      return;
    }

    const promo = await createPromo(promotionDetails);
    navigate(
      `/admin/gyms/${gymUid}/promotions/edit/${promo.id}?inviteeDrawerOpen=true`
    );
  };

  return (
    <PageLayout
      leftButton={
        <Typography
          onClick={() => navigate(`/admin/gyms/${gymUid}/promotions`)}
        >
          <ArrowBack />
        </Typography>
      }
      selected="Promotion"
      pageTitle="Create Promotion"
    >
      <Box width="100%" mt="36px">
        <Flex justifyContent="space-between" alignItems="center">
          <Box mb="16px">
            <Typography variant="h6">Promotion Info</Typography>
          </Box>
          <Box mb="16px">
            <Button
              type="submit"
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
            >
              Create Promotion
            </Button>
          </Box>
        </Flex>
        <Card>
          <CardContent>
            <Box mb="16px">
              <TextField
                name="name"
                label="Promotion name"
                value={promotionDetails.name}
                onChange={handleInputChange}
                className={classes.textField}
                error={formErrors.name}
              />
            </Box>
            <Box mb="16px">
              <TextField
                name="description"
                label="Description"
                value={promotionDetails.description}
                onChange={handleInputChange}
                className={classes.textField}
                error={formErrors.description}
                multiline
              />
            </Box>
            <Box mb="16px">
              <TextField
                name="promotion_active_day_threshold"
                label="Active Day Threshold"
                value={promotionDetails.promotion_active_day_threshold}
                onChange={handleInputChange}
                className={classes.textField}
                error={formErrors.promotion_active_day_threshold}
                type="number"
              />
            </Box>
          </CardContent>
        </Card>
        <Box mt="16px" mb="16px">
          <Card>
            <CardContent>
              <Box justifyContent="center">
                <Flex mb="16px" justifyContent="space-between">
                  <Box>
                    <Typography variant="h6">Invitees</Typography>
                  </Box>
                  <Box>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleInitialPromoSaveOrToggle}
                    >
                      <Add />
                      Add Invitees
                    </Button>
                  </Box>
                </Flex>
                <Divider />
                <InviteesContainer>
                  <Typography variant="body1"> No invitees yet</Typography>
                </InviteesContainer>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box mt="26px">
          <Button
            type="submit"
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={handleSubmit}
          >
            Create Promotion
          </Button>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default withStyles(styles)(CreatePromotion);
