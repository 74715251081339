import React, { useContext } from 'react';
import PageLayout from './page-layout';
import { Box } from '@rebass/grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Button from '@material-ui/core/Button';
import { BeltComponent } from './atom/belt';
import get from 'lodash.get';
import ListAvatar from '../components/atom/list-avatar';
import { Link } from '@reach/router';
import { useUser } from '../lib/hooks/use-user';
import { AppContext } from '../app';

const styles = {
  textField: {
    width: '100%',
  },
  button: {
    width: '100%',
    margin: '16px 0 8px',
  },
  border: {
    borderBottom: '1px solid #5a5858',
  },
  icon: {
    width: '80px',
    height: '80px',
  },
  forgotPin: {
    width: '280px',
  },
};
const ChildrenAccounts = (props) => {
  const { classes = {} } = props;
  const { loggedInUserId } = useContext(AppContext);
  const { user = {} } = useUser(loggedInUserId);

  return (
    <PageLayout selected="Children" pageTitle="Family Accounts">
      <Box width="100%" mt="8px">
        <List className={classes.button} dense>
          {user.children.map((child, i) => {
            if (i < 10) {
              return (
                <Link to={`/profile/${child.uid}`} key={child.uid}>
                  <ListItem
                    key={`${i}-${child.name}`}
                    className={classes.border}
                    button
                  >
                    <ListItemAvatar>
                      <ListAvatar
                        src={get(child, 'avatar_url')}
                        name={get(child, 'name')}
                      />
                    </ListItemAvatar>
                    <ListItemText>{child.name}</ListItemText>
                    <BeltComponent belt={child.belt} />
                  </ListItem>
                </Link>
              );
            } else return null;
          })}
        </List>
        <Link to="new">
          <Button className={classes.button} variant="outlined" color="primary">
            Add Family Member
          </Button>
        </Link>
      </Box>
    </PageLayout>
  );
};

export default withStyles(styles)(ChildrenAccounts);
