import appConfig from '../../config/app';
import { get } from 'lodash';
import { getAuthHeaders } from '../fetchers/auth';

export async function updateUserData(userData, userId) {
  const options = await getAuthHeaders();
  const updatedUserInfo = {
    name: userData.name,
    avatar_url: userData.avatar_url,
    dob: new Date(userData.dob).toISOString(),
    start_date: new Date(userData.start_date).toISOString(),
    jits_start_date: new Date(userData.jits_start_date).toISOString(),
    belt_size_id: get(userData, 'belt_size.id'),
    belt_id: get(userData, 'belt.id'),
    gym_program_id: get(userData, 'gym_program.id'),
    gym_id: get(userData, 'gym.id'),
    description: userData.description,
  };

  return fetch(`${appConfig.baseApiUrl}/api/v1/users/${userId}`, {
    method: 'PATCH',
    headers: {
      ...options,
    },
    body: JSON.stringify(updatedUserInfo),
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => console.log(err));
}
