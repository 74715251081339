import firebase from 'firebase';

export function addAvatarImage(file) {
  const storage = firebase.storage().ref();
  if (file && file.name) {
    const fileRef = storage.child(`users/${file.name}`);
    return fileRef
      .put(file)
      .then((res) => {
        return fileRef.getDownloadURL();
      })
      .catch((err) => console.log(err));
  }
}
