import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@rebass/grid';
import Typography from '@material-ui/core/Typography';
import { CardContent } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import styled from 'styled-components';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  font-size: 12px;
  padding: 16px;
  border-radius: 5px 5px;
  box-sizing: border-box;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const MobileSelector = ({
  classes,
  isDrawerOpen,
  toggleDrawer,
  title,
  children,
}) => {
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={isDrawerOpen}
      onClose={toggleDrawer}
      onOpen={() => {}}
      PaperProps={{
        style: {
          borderRadius: '20px 20px 0 0',
          background: '#303030',
        },
      }}
      transitionDuration={{ enter: 300, exit: 250 }} // Adjust the animation duration (in milliseconds)
    >
      <>
        <Header>
          <IconWrapper onClick={toggleDrawer}>
            <Close />
          </IconWrapper>
          <Box>
            <Typography
              variant="subtitle1"
              style={{
                marginRight: '10px',
              }}
            >
              {title}
            </Typography>
          </Box>
          <div />
        </Header>
        <Box>
          <CardContent>
            <Box onClick={toggleDrawer}>{children}</Box>
          </CardContent>
        </Box>
      </>
    </SwipeableDrawer>
  );
};

const styles = {
  optionLink: {
    padding: '16px',
  },
};

export default withStyles(styles)(MobileSelector);
