import React, { useState, useEffect, useContext } from 'react';
import { useGymUsers } from '../../../lib/hooks/use-gym-users';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import Card from '@material-ui/core/Card';
import { Divider } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Close from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';
import { Flex, Box } from '@rebass/grid';
import Profile from './profile';
import ListAvatar from '../../atom/list-avatar';
import TablePagination from '@mui/material/TablePagination';
import { useIsMobile } from '../../../lib/hooks/use-is-mobile';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowUp from '@material-ui/icons/ArrowDropUp';
import { fetchGymUsers } from '../../../lib/fetchers/gyms';
import Checkbox from '@mui/material/Checkbox';
import { useIsDesktop } from '../../../lib/hooks/use-is-desktop';
import { LoadingDots } from '../../atom/loading-dots';
import ProfileForm from '../../organisim/profile-form';
import { updateUserData } from '../../../lib/mutations/update-user';
import { useBeltSize } from '../../../lib/hooks/use-belt-size';
import { useGymPrograms } from '../../../lib/hooks/use-gym-program';
import { AppContext } from '../../../app';
import { useUser } from '../../../lib/hooks/use-user';
import { useUserFormEdit } from '../../../lib/hooks/use-user-form-edit';
import AlertDialog from '../../organisim/alert-dialog';
import { useModal } from '../../../lib/hooks/use-modal';

import { useQueryClient } from 'react-query';
import { ArrowForward } from '@material-ui/icons';
import { ArrowBack } from '@material-ui/icons';

const TableRow = styled.div`
  display: grid;
  min-width: 800px;
  grid-template-columns: 0.5fr 0.5fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: 'checkbox avatar name belt beltSize startDate jitsStartDate age activeDays';
  position: relative;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  @media (min-width: 850px) {
    &:hover {
      background-color: #5a5858;
    }
  }
`;

const TableCell = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  height: 50px;
  width: auto;
`;

const TableHeader = styled(TableRow)`
  &:hover {
    background-color: #2b2b2b;
  }
  background: #2b2b2b;
`;

const AgeCell = styled(TableCell)`
  @media (max-width: 1075px) {
    justify-content: center;
  }
`;

const LastCell = styled(TableCell)`
  display: flex;
  grid-area: activeDays;
  position: sticky;
  right: 0;
  padding-right: 16px;

  @media (max-width: 1075px) {
    background: #424242;
    padding-right: 16px;
    justify-content: center;
    padding-right: 0px;
    min-width: 120px;
  }
`;

const LastCellHeader = styled(LastCell)`
  @media (max-width: 1075px) {
    background: #2b2b2b;
  }
`;

const LeftAlign = styled(TableCell)`
  justify-content: left;
  padding-left: 16px;
`;

const NameCell = styled(LeftAlign)`
  display: flex;
  grid-area: name;
  position: sticky;
  left: 0;
  padding-left: 16px;
`;

const NameHeader = styled(NameCell)`
  background: #2b2b2b;
  @media (max-width: 1075px) {
    background: #2b2b2b;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 50px;
  background: #03a9f4;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 16px;
  box-sizing: border-box;
`;

const FadeProfile = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadeIn 0.6s;
  transition: background-color 0.3s;
  opacity: 1;
`;

const FadeEdit = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadeIn 0.6s;
  transition: background-color 0.3s;
  opacity: 1;
`;

const UserListItemWrapper = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadeIn 0.3s;
  transition: background-color 0.3s;
  opacity: 1;
  border-bottom: 1px solid #5a5858;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const Wrapper = styled.div``;

const InnerWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  box-sizing: border-box;
  position: relative;
  min-height: 600px;
`;

export const UserTableList = ({
  filters = {},
  searchTerm,
  setSearchTerm,
  gymUid,
  csv = false,
  handleAddInvitee = () => {},
  handleDeleteUserPromo = () => {},
  invitees = [],
}) => {
  const { isMobile } = useIsMobile();
  const { isDesktop } = useIsDesktop();
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [activeMember, setActiveMember] = useState({});
  const { user: activeMemberCopy } = useUser(activeMember?.uid);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isNameCellLeftmost, setIsNameCellLeftmost] = useState(true);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const { program = '', startDate, endDate, lastPromoted } = filters;
  const { loggedInUserId } = useContext(AppContext);
  const { user = {} } = useUser(loggedInUserId);
  const { beltSizes = [] } = useBeltSize(user?.gym?.id);
  const { gymPrograms = [] } = useGymPrograms(user?.gym?.id);
  const [editProfile, setEditProfile] = useState(false);
  const {
    isOpen: isFormAlertOpen,
    openModal: openFormAlert,
    closeModal: closeFormAlert,
  } = useModal();
  const queryClient = useQueryClient();

  const onSubmitCallback = (status) => {
    if (status === 'success') {
      setEditProfile(false);
      queryClient.invalidateQueries('gym-users');
    }
  };

  useEffect(() => {
    setActiveMember(activeMember);
  }, [activeMember]);

  const {
    updateTextField,
    updateSelectField,
    updateErrors,
    uploadImage,
    isUpdatingUser,
    isUploadingImage,
    isFormDirty,
    setIsFormDirty,
    submitForm,
  } = useUserFormEdit(activeMember, setActiveMember, onSubmitCallback, true);

  const inviteeIdHash = invitees.reduce((acc, invitee) => {
    acc[invitee.user_id] = { ...invitee };
    return acc;
  }, {});

  const params = {
    program,
    startDate,
    endDate,
    currentPage: currentPage + 1,
    perPage: rowsPerPage,
    sortBy,
    sortOrder,
    lastPromoted,
  };

  const { users = {}, loading } = useGymUsers(gymUid, searchTerm, params);

  useEffect(() => {
    if (csv) {
      handleCsvExport();
    }
  }, [csv]);

  const handleCsvExport = async () => {
    const response = await fetchGymUsers(gymUid, searchTerm, {
      ...params,
      csv,
    });

    if (!response.ok) {
      throw new Error('Failed to fetch CSV data');
    }

    const csvData = await response.text();
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'gym-users-report.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
    }
  }, [searchTerm, startDate, endDate, program, sortBy]);

  useEffect(() => {
    const handleScroll = (e) => {
      setIsNameCellLeftmost(e.target.scrollLeft === 0);
    };

    const wrapper = document.getElementById('inner-wrapper');
    wrapper.addEventListener('scroll', handleScroll);

    return () => {
      wrapper.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleColumnSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleSetEditProfile = (status) => {
    if (isFormDirty) {
      openFormAlert();
    } else {
      setEditProfile(status);
    }
  };

  const toggleDrawer = (member) => {
    setEditProfile(false);
    setActiveMember(member);
    setisDrawerOpen(!isDrawerOpen);
  };

  const handleCheckClick = async (e, member) => {
    const checked = e.target.checked;
    try {
      if (checked) {
        handleAddInvitee(member);
      } else {
        // this logic is so we can delete the user promo by the user_promo_id
        // an not have to use user_id / promo_id
        const inviteeId = member ? inviteeIdHash[member?.id]?.id : false;
        if (inviteeId) {
          handleDeleteUserPromo(inviteeId);
        }
      }
    } catch (e) {
      console.error(e, 'cathing in table list');
    }
  };

  const convertDateToYears = (date) => {
    const today = new Date();
    const birthDate = new Date(date);
    let ageInMonths = (today.getFullYear() - birthDate.getFullYear()) * 12;
    ageInMonths -= birthDate.getMonth();
    ageInMonths += today.getMonth();
    const age = ageInMonths / 12;
    return age.toFixed(2); // returns age with two decimal places
  };

  const Arrows = () => (
    <Box width="30px">
      <ArrowUp style={{ marginBottom: '-15px' }} />
      <ArrowDropDown style={{ marginTop: '-4px' }} />
    </Box>
  );

  return (
    <Wrapper>
      <Card>
        <CardContent>
          <Flex mb="16px" justifyContent="space-between">
            <Box p="8px">
              <Typography variant="h6">Results</Typography>
            </Box>
            <TablePagination
              style={{ color: 'white' }}
              component="div"
              count={users?.meta?.total_items || 0}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Flex>
        </CardContent>
        <InnerWrapper id="inner-wrapper">
          <TableHeader>
            <TableCell>
              <div />
            </TableCell>

            <LeftAlign>
              <Typography variant="subtitle1">Img</Typography>
            </LeftAlign>
            <NameHeader
              isLeftmost={isNameCellLeftmost}
              onClick={() => handleColumnSort('name')}
            >
              <Typography variant="subtitle1">Name</Typography>
              <Arrows />
            </NameHeader>
            {isDesktop && (
              <>
                <TableCell onClick={() => handleColumnSort('belt_name')}>
                  <Typography variant="subtitle1">Belt</Typography>
                  <Arrows />
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Belt Size</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Gym Years</Typography>
                </TableCell>
                <TableCell onClick={() => handleColumnSort('gym_program')}>
                  <Typography variant="subtitle1">Program</Typography>
                  <Arrows />
                </TableCell>
                <AgeCell onClick={() => handleColumnSort('dob')}>
                  <Typography variant="subtitle1">Age</Typography>
                  <Arrows />
                </AgeCell>
              </>
            )}
            <LastCellHeader onClick={() => handleColumnSort('active_days')}>
              <Typography variant="subtitle1">Actv.Days</Typography>
              <Arrows />
            </LastCellHeader>
          </TableHeader>
          <Divider />
          {users?.users?.length === 0 && !loading && (
            <Flex
              style={{
                height: '200px',
              }}
              alignItems="center"
              justifyContent="center"
            >
              <div>No members found</div>
            </Flex>
          )}
          {loading && <LoadingDots />}
          {users?.users?.length > 0 &&
            users?.users?.map((member) => (
              <UserListItemWrapper key={member.id}>
                <div onClick={() => toggleDrawer(member)}>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        sx={{
                          color: 'white',
                          '&.Mui-checked': {
                            color: '#03a9f4',
                          },
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => handleCheckClick(e, member)}
                        checked={!!inviteeIdHash[member?.id]}
                      />
                    </TableCell>
                    <LeftAlign>
                      <ListAvatar src={member.avatar_url} />
                    </LeftAlign>
                    <NameCell>
                      <Typography variant="body1">{member.name}</Typography>
                    </NameCell>
                    {isDesktop && (
                      <>
                        <TableCell>
                          <Typography variant="body1">
                            {member.belt?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {member.belt_size?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {convertDateToYears(member.start_date)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1">
                            {member.gym_program?.name}
                          </Typography>
                        </TableCell>
                        <AgeCell>
                          <Typography variant="body1">
                            {Math.floor(convertDateToYears(member.dob))}
                          </Typography>
                        </AgeCell>
                      </>
                    )}

                    <LastCell>
                      <Typography variant="body1">
                        {member.active_days}
                      </Typography>
                    </LastCell>
                  </TableRow>
                </div>
              </UserListItemWrapper>
            ))}
        </InnerWrapper>
        <TablePagination
          style={{ color: 'white' }}
          component="div"
          count={users?.meta?.total_items || 0}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={isDrawerOpen}
        onClose={toggleDrawer}
        PaperProps={{
          style: {
            height: '100vh',
            background: '#303030',
            width: isMobile ? '100%' : '60%',
          },
        }}
        transitionDuration={{ enter: 300, exit: 250 }} // Adjust the animation duration (in milliseconds)
        BackdropProps={{ invisible: isMobile ? true : false }}
      >
        <div>
          <Header>
            <IconWrapper onClick={toggleDrawer}>
              <Close />
            </IconWrapper>
            <Typography variant="h6">Member Profile</Typography>
            <div />
          </Header>
          <Flex
            justifyContent="flex-end"
            pt="20px"
            style={{
              cursor: 'pointer',
            }}
          >
            {!editProfile && (
              <Flex onClick={() => setEditProfile(true)} alignItems="center">
                <Box>Edit Profile</Box>
                <Box ml="8px" mr="22px">
                  <ArrowForward />
                </Box>
              </Flex>
            )}
          </Flex>
          <Flex
            style={{
              cursor: 'pointer',
            }}
            justifyContent="flex-start"
          >
            {editProfile && (
              <Flex
                onClick={() => handleSetEditProfile(false)}
                alignItems="center"
              >
                <Flex ml="22px" mr="8px">
                  <ArrowBack />
                </Flex>
                <Flex> Profile</Flex>
              </Flex>
            )}
          </Flex>
          {editProfile ? (
            <FadeEdit>
              <Box p="24px">
                <Card
                  style={{
                    height: '100%',
                    overflow: 'auto',
                    padding: '16px',
                  }}
                >
                  <ProfileForm
                    beltSizes={beltSizes}
                    gymPrograms={gymPrograms}
                    updateUserData={updateUserData}
                    user={activeMember}
                    setState={setActiveMember}
                    updateTextField={updateTextField}
                    updateSelectField={updateSelectField}
                    upDateErrors={updateErrors}
                    uploadImage={uploadImage}
                    isUpdatingUser={isUpdatingUser}
                    isImageUploading={isUploadingImage}
                    signup={false}
                    setFormIsDirty={setIsFormDirty}
                    submitForm={submitForm}
                  />
                </Card>
              </Box>
            </FadeEdit>
          ) : (
            <FadeProfile>
              <Profile user={activeMember} />
            </FadeProfile>
          )}
        </div>
      </Drawer>
      <AlertDialog
        message="You must save your changes before leaving the edit page. Are you sure you want to leave before saving?"
        title="Are you sure you want to cancel changes?"
        open={isFormDirty && isFormAlertOpen}
        handleCancel={() => {
          closeFormAlert();
        }}
        handleAgree={() => {
          closeFormAlert();
          setActiveMember(activeMemberCopy);
          setIsFormDirty(false);
          setEditProfile(false);
        }}
        buttonCancelText="Cancel"
        buttonAgreeText="Confirm"
      />
    </Wrapper>
  );
};

export default UserTableList;
