import React from 'react';
import styled from 'styled-components';
import get from 'lodash.get';

const Belt = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${(props) =>
    get(props, 'belt.color', false) ? props.belt.color : 'white'};
  border: 1px solid
    ${(props) => (get(props, 'belt.secondary') ? props.belt.secondary : 'none')};
  border-radius: 50%;
`;

export const belts = {
  white: { color: '#e0dada', value: 'White Belt' },
  grey: { color: '#948b8b', value: 'Grey Belt' },
  greyWhite: {
    color: '#948b8b',
    secondary: '#948b8b',
    value: 'Grey White Belt',
  },
  greyBlack: {
    color: '#948b8b',
    secondary: '#191515',
    value: 'Grey Black Belt',
  },
  yellow: { color: '#94942d', value: 'Yellow Belt' },
  yellowWhite: {
    color: '#94942d',
    secondary: '#948b8b',
    value: 'Yellow White Belt',
  },
  yellowBlack: {
    color: '#94942d',
    secondary: '#191515',
    value: 'Yellow Black Belt',
  },
  orange: { color: '#9a6a13', value: 'Orange Belt' },
  orangeWhite: {
    color: '#9a6a13',
    secondary: '#948b8b',
    value: 'Orange White Belt',
  },
  orangeBlack: {
    color: '#9a6a13',
    secondary: '#191515',
    value: 'Orange Black Belt',
  },
  green: { color: '#346134', value: 'Green Belt' },
  greenWhite: { color: '#346134', secondary: '#948b8b', value: 'Green White' },
  greenBlack: {
    color: '#346134',
    secondary: '#191515',
    value: 'Green Black Belt',
  },
  blue: { color: '#3c3cf3', value: 'Blue Belt' },
  purple: { color: '#7f327f', value: 'Purple Belt' },
  brown: { color: '#7d3535', value: 'Brown Belt' },
  black: { color: '#191515', value: 'Black Belt' },
};

export const BeltComponent = ({ belt }) => {
  return <Belt belt={belts[belt]} />;
};

export default BeltComponent;
