export const depupeUserPromos = (userPromos) => {
  const userPromoSet = new Set();
  return userPromos.filter((userPromo) => {
    const duplicate = userPromoSet.has(userPromo.user_id);
    userPromoSet.add(userPromo.user_id);
    return !duplicate;
  });
};

export const buildUserPromo = (userList, promo) => {
  const userPromoArray = userList.map((invitee) => {
    return {
      user_id: invitee.user_id,
      promotion_id: promo.id,
    };
  });

  return {
    user_promotions: userPromoArray,
  };
};
