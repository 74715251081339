import appConfig from '../../config/app';
import { get } from 'lodash';
import { getAuthHeaders } from '../fetchers/auth';

export async function createUser(userData, userId) {
  const options = await getAuthHeaders();
  const updatedUserInfo = {
    name: get(userData, 'name'),
    email: get(userData, 'email'),
    parent_id: get(userData, 'parent_id'),
    avatar_url: get(userData, 'avatar_url'),
    gym_id: get(userData, 'gym.id'),
    dob: new Date(get(userData, 'dob') || Date.now()).toISOString(),
    start_date: new Date(
      get(userData, 'start_date') || Date.now()
    ).toISOString(),
    jits_start_date: new Date(
      get(userData, 'jits_start_date') || Date.now()
    ).toISOString(),
    belt_size_id: get(userData, 'belt_size.id'),
    gym_program_id: get(userData, 'gym_program.id'),
    belt_id: get(userData, 'belt.id'),
    description: get(userData, 'description'),
    uid: get(userData, 'uid'),
  };

  if (userData) {
    return fetch(`${appConfig.baseApiUrl}/api/v1/users`, {
      method: 'POST',
      headers: {
        ...options,
      },
      body: JSON.stringify(updatedUserInfo),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  }
}
