import { useQuery } from 'react-query';
import { getGymPrograms } from '../fetchers/gym-programs';

export function useGymPrograms(gymId) {
  const { data = [], isLoading, error } = useQuery(
    ['gymPrograms', gymId],
    () => getGymPrograms(gymId),
    {
      enabled: !!gymId,
      staleTime: Infinity,
    },
  );
  return { gymPrograms: data, isLoading, error };
}
