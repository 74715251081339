import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { VictoryBar, VictoryStack, VictoryAxis, VictoryChart } from 'victory';
import { ChartContainer, ChartOverlay } from './styles';
import styled from 'styled-components';
import { Flex } from '@rebass/grid';
import Button from '@material-ui/core/Button';

const LabelBox = styled.div`
  width: 10px;
  height: 10px;
  background: ${(props) => props.color};
  margin-right: 10px;
  border: 1px solid white;
`;

const BoxLabelFont = styled.div`
  font-size: 12px;
`;
export const CheckinChartCard = ({
  checkinTrendsData = [],
  secondDataSet = [],
  title,
  labelConfig = {},
  maxCheckins = 10,
  showDetailsButton = false,
}) => {
  const firstArrayValue = [...checkinTrendsData.map((item) => item.y)];
  const secondArrayValue = [...secondDataSet.map((item) => item.y)];

  const combinedData = [...firstArrayValue, ...secondArrayValue];

  const upperAxisBound = Math.max(...combinedData, maxCheckins);

  const { label1 = null, label2 = null } = labelConfig;

  return (
    <Card>
      <CardContent>
        <Flex justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{title || 'Attendance'}</Typography>
          {showDetailsButton && (
            <Button variant="outlined" color="primary">
              <Typography variant="body1">Details</Typography>
            </Button>
          )}
        </Flex>
      </CardContent>
      <ChartContainer>
        <ChartOverlay />
        <Flex pl="20px">
          {label1 && (
            <Flex mr="10px">
              <LabelBox color="#A900E9" />
              <BoxLabelFont>{label1}</BoxLabelFont>
            </Flex>
          )}
          {label2 && (
            <Flex mr="10px">
              <LabelBox color="#00E9A9" />
              <BoxLabelFont>{label2}</BoxLabelFont>
            </Flex>
          )}
        </Flex>
        <VictoryChart
          style={{
            labels: {
              fill: 'white',
              fontSize: 5,
              fontWeight: 'regular',
            },
          }}
          domainPadding={20}
        >
          <VictoryStack colorScale={['#A900E9', '#00E9A9']}>
            <VictoryBar data={checkinTrendsData} />
            {secondDataSet.length > 0 && <VictoryBar data={secondDataSet} />}
          </VictoryStack>
          <VictoryAxis
            dependentAxis // This specifies that this axis is for the Y-axis
            domain={[0, upperAxisBound]}
            style={{
              axis: {
                stroke: 'white', // CHANGE COLOR OF Y-AXIS
              },
              tickLabels: {
                fill: 'white', // CHANGE COLOR OF Y-AXIS LABELS
              },
              grid: {
                stroke: 'gray', // Color of the grid lines
                strokeDasharray: '2, 2', // Dash pattern for the grid lines
              },
            }}
          />

          <VictoryAxis
            style={{
              axis: {
                stroke: 'white', //CHANGE COLOR OF X-AXIS
              },
              tickLabels: {
                fill: 'white', //CHANGE COLOR OF X-AXIS LABELS
              },
            }}
          />
        </VictoryChart>
      </ChartContainer>
    </Card>
  );
};

export default CheckinChartCard;
