import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import get from 'lodash.get';
import ListAvatar from '../atom/list-avatar';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

const ListItemContainer = styled.div`
  width: 100%;
`;

const UserListItem = ({
  style = {},
  user,
  children,
  handleClick = () => {},
}) => {
  const avatarUrl = get(user, 'avatar_url');
  return (
    <ListItemContainer>
      <ListItem
        style={{
          cursor: 'pointer',
          ...style,
        }}
      >
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Flex onClick={() => handleClick(user)}>
            <ListItemAvatar>
              <ListAvatar
                style={{
                  ...style.img,
                }}
                src={avatarUrl}
                name={get(user, 'name')}
              />
            </ListItemAvatar>
            <ListItemText>{user.name}</ListItemText>
          </Flex>
          <Box>{children}</Box>
        </Flex>
      </ListItem>
    </ListItemContainer>
  );
};

export default UserListItem;
