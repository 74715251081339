import React, { useContext, useState } from 'react';
import { useUserPromotions } from '../../../lib/hooks/use-promotions';
import UserListItem from '../../organisim/user-list-item';
import { format } from 'date-fns';
import { Box, Flex } from '@rebass/grid';
import TextField from '@material-ui/core/TextField';
import {
  Button,
  CardContent,
  Card,
  Divider,
  Typography,
} from '@material-ui/core';
import { createOrUpdateUsersPromo } from '../../../lib/mutations/create-promotion';
import { useQueryClient } from 'react-query';
import PromotionInfo from '../../admin/components/promotion-info';
import { ArrowForward, Add } from '@material-ui/icons';
import { navigate, useParams } from '@reach/router';
import Modal from '@material-ui/core/Modal';
import { useModal } from '../../../lib/hooks/use-modal';
import Search from '../../atom/search';
import { AppContext } from '../../../app';
import { useUser } from '../../../lib/hooks/use-user';
import CloseIcon from '@material-ui/icons/Close';
import { useIsMobile } from '../../../lib/hooks/use-is-mobile';
import { LoadingDots } from '../../atom/loading-dots';

export const Promotion = ({ promotion = {} }) => {
  const { userPromotions = [], userPromosLoading } = useUserPromotions(
    promotion.id
  );
  const { loggedInUserId } = useContext(AppContext);
  const { user } = useUser(loggedInUserId);
  const { gymUid } = useParams();
  const queryClient = useQueryClient();
  const { id = 0 } = promotion;
  const { closeModal, openModal, isOpen } = useModal();
  const [activeUserPromo, setActiveUserPromo] = useState({});
  const { isMobile } = useIsMobile();

  const handlePromotion = async (promotionData) => {
    try {
      const userPromo = {
        user_promotions: [
          {
            user_id: promotionData.user_id,
            belt_from: promotionData?.belt?.id,
            belt_to: promotionData?.next_belt?.id,
            promotion_id: promotionData.promotion_id,
            promoted: true,
            promoted_date:
              promotionData?.promoted_date || format(Date.now(), 'YYYY-MM-DD'),
          },
        ],
      };
      await createOrUpdateUsersPromo(userPromo);
      queryClient.invalidateQueries(['user-promotions', id]);
      queryClient.invalidateQueries(['promotions']);
      handleCloseModal();
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const handleCloseModal = () => {
    closeModal();
    setActiveUserPromo({});
  };

  const handleModalOpen = (userPromo) => {
    openModal(true);
    setActiveUserPromo(userPromo);
  };

  const handleFromBeltChange = (belt) => {
    setActiveUserPromo((prev) => ({
      ...prev,
      belt: belt,
    }));
  };
  const handleToBeltChange = (belt) => {
    setActiveUserPromo((prev) => ({
      ...prev,
      next_belt: belt,
    }));
  };

  const handlePromotionDateChange = (date) => {
    setActiveUserPromo((prev) => ({
      ...prev,
      promoted_date: date,
    }));
  };

  const handleAddInvitees = () => {
    navigate(
      `/admin/gyms/${gymUid}/promotions/edit/${promotion.id}?inviteeDrawerOpen=true&backUrl=/admin/gyms/${gymUid}/promotions?promoId=${promotion.id}`
    );
  };

  return (
    <div>
      <Box mb="8px">
        <PromotionInfo promo={promotion} />
      </Box>
      <Divider />
      <Flex mt="24px" mb="16px" justifyContent="space-between">
        <Typography variant="h6">Invitees</Typography>
        <Button onClick={handleAddInvitees} variant="outlined" color="primary">
          <Add />
          Add Invitees
        </Button>
      </Flex>
      <Divider />
      {userPromosLoading && <LoadingDots />}
      {userPromotions.map((userPromo) => (
        <div key={userPromo.user_id}>
          <UserListItem
            user={userPromo}
            style={{
              backgroundColor:
                userPromo.is_checked_in_now && !userPromo.promoted_date
                  ? '#175f80'
                  : 'transparent',
            }}
          >
            <Flex alignItems="center">
              {!isMobile && (
                <Flex mr="16px">
                  <Typography variant="caption">
                    {userPromo?.belt?.name || 'No Belt'}
                  </Typography>
                  <Box ml="8px" mr="8px">
                    <ArrowForward />
                  </Box>
                  <Typography variant="caption">
                    {userPromo?.next_belt?.name || 'No Belt'}
                  </Typography>
                </Flex>
              )}
              <Button
                variant="outlined"
                onClick={() => handleModalOpen(userPromo)}
                disabled={userPromo.promoted_date}
              >
                <Typography variant="body1">
                  {userPromo?.promoted_date ? 'Promoted' : 'Promote'}
                </Typography>
              </Button>
            </Flex>
          </UserListItem>

          <Divider />
          <Modal open={isOpen && activeUserPromo.id === userPromo.id}>
            <Box
              style={{
                width: '90%',
                maxWidth: '500px',
                height: 'auto',
                zIndex: 1001,
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Card>
                <CardContent>
                  <Flex mb="8px" justifyContent="space-between">
                    <Typography variant="h6">
                      Promote {activeUserPromo?.name}
                    </Typography>
                    <CloseIcon onClick={handleCloseModal} />
                  </Flex>
                  <Divider />
                  <Box mb="8px" mt="16px">
                    <Search
                      label="From Belt"
                      gymId={user?.gym?.id}
                      onChange={(event) =>
                        handleFromBeltChange(event?.target?.value)
                      }
                      value={activeUserPromo?.belt}
                    />
                  </Box>
                  <Box>
                    <Search
                      label="To Belt"
                      gymId={user?.gym?.id}
                      onChange={(event) =>
                        handleToBeltChange(event?.target?.value)
                      }
                      value={activeUserPromo?.next_belt}
                    />
                  </Box>
                  <Box mt="16px">
                    <TextField
                      type="date"
                      value={
                        activeUserPromo.promoted_date ||
                        format(Date.now(), 'YYYY-MM-DD')
                      }
                      onChange={(e) =>
                        handlePromotionDateChange(e?.target?.value)
                      }
                      label="Promoted Date"
                    />
                  </Box>
                  <Flex mt="32px" justifyContent="flex-end">
                    <Button
                      onClick={() => handlePromotion(activeUserPromo)}
                      variant="outlined"
                      color="primary"
                    >
                      Promote
                    </Button>
                  </Flex>
                </CardContent>
              </Card>
            </Box>
          </Modal>
        </div>
      ))}
    </div>
  );
};
