import dotenv from 'dotenv';
dotenv.config();

const appConfig = {
  default: {
    baseApiUrl: 'https://rails-be-gym.fly.dev',
  },
  development: {
    baseApiUrl: 'http://localhost:3000',
  },
  staging: {
    baseApiUrl: 'https://rails-be-gym.fly.dev',
  },
  production: {
    baseApiUrl: 'https://rails-be-gym.fly.dev',
  },
};

export default appConfig[process.env.NODE_ENV] || appConfig.default;
