import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  Button,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import PageLayout from '../../page-layout';
import { useDrawer } from '../../../lib/hooks/use-drawer';
import { navigate, useParams, useLocation } from '@reach/router';
import { usePromotion } from '../../../lib/hooks/use-promotions';
import { updatePromo } from '../../../lib/mutations/create-promotion';
import UserPromoSearch from '../components/user-promo-search';
import { buildUserPromo } from '../../../lib/utils/promo';
import {
  createOrUpdateUsersPromo,
  deleteUsersPromo,
} from '../../../lib/mutations/create-promotion';
import { useUserPromotions } from '../../../lib/hooks/use-promotions';
import UserListItem from '../../organisim/user-list-item';
import { useQueryClient } from 'react-query';
import { Flex } from '@rebass/grid';
import { Add, ArrowBack, Delete } from '@material-ui/icons';
import { LoadingDots } from '../../atom/loading-dots';
import styled from 'styled-components';

const InviteesContainer = styled.div`
  display: flex;
  min-height: 150px;
  justify-content: center;
  align-items: center;
}`;

const styles = {
  textField: {
    width: '100%',
  },
  button: {
    width: '100%',
    margin: '16px 0 8px',
  },
  formControl: {
    margin: '16px 0',
    width: '100%',
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
};

const EditPromotion = ({ classes }) => {
  const { gymUid, promoId } = useParams();
  const updatedPromo = useRef(false);
  const queryClient = useQueryClient();
  const [invitees, setInvitees] = useState([]);

  const queryParams = new URLSearchParams(useLocation().search);
  const isInviteeDrawerOpen = queryParams.get('inviteeDrawerOpen');
  const { isDrawerOpen, toggleDrawer } = useDrawer();

  const { promotion } = usePromotion(promoId);
  const { userPromotions, userPromosLoading } = useUserPromotions(promoId);
  const [promotionDetails, setPromotionDetails] = useState({
    name: '',
    description: '',
    promotion_active_day_threshold: 30,
  });

  useEffect(() => {
    if (promotion && !updatedPromo.current) {
      setPromotionDetails(promotion);
      updatedPromo.current = true;
    }
  }, [promotion]);

  useEffect(() => {
    if (userPromotions) {
      setInvitees(userPromotions);
    }
  }, [userPromotions]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPromotionDetails({
      ...promotionDetails,
      [name]: value,
    });
  };

  useEffect(() => {
    if (isInviteeDrawerOpen) {
      toggleDrawer();
    }
  }, []);

  const handlePromoUpdate = async () => {
    await updatePromo(promotionDetails, promoId);
    queryClient.invalidateQueries(['promotion', promoId]);
    navigate(`/admin/gyms/${gymUid}/promotions`);
  };

  const handleUserPromoSubmit = async (invite) => {
    try {
      const userPromo = buildUserPromo(invite, promotion);
      if (userPromo.user_promotions.length !== 0) {
        let res = await createOrUpdateUsersPromo(userPromo);
        if (res.failed_updates?.length > 0 && res.failed_updates[0].errors) {
          throw new Error(res.failed_updates[0].errors);
        }
        queryClient.invalidateQueries(['user-promotions', promoId]);
      }
    } catch (e) {
      return e;
    }
  };

  const handleDeleteUserPromo = async (userPromoId) => {
    await deleteUsersPromo([userPromoId]);
    queryClient.invalidateQueries(['user-promotions', promoId]);
  };

  const handleAddInvitee = (user) => {
    const userWithUserID = { user_id: user.id };
    handleUserPromoSubmit([userWithUserID]);
  };
  return (
    <PageLayout
      leftButton={
        <Box onClick={() => navigate(`/admin/gyms/${gymUid}/promotions`)}>
          <ArrowBack />
        </Box>
      }
      selected="Promotion"
      pageTitle="Edit Promotion"
    >
      <Box width="100%" mt="36px">
        <Flex justifyContent="space-between" alignItems="center">
          <Box mb="16px">
            <Typography variant="h6">Promotion Info</Typography>
          </Box>
          <Box mb="16px">
            <Button
              type="submit"
              className={classes.button}
              variant="outlined"
              color="primary"
              onClick={handlePromoUpdate}
            >
              Save changes
            </Button>
          </Box>
        </Flex>
        <Card>
          <CardContent>
            <Box mb="16px">
              <TextField
                name="name"
                label="Promotion name"
                value={promotionDetails.name}
                onChange={handleInputChange}
                className={classes.textField}
              />
            </Box>
            <Box mb="16px">
              <TextField
                name="description"
                label="Description"
                value={promotionDetails.description}
                onChange={handleInputChange}
                className={classes.textField}
                multiline
              />
            </Box>
            <Box mb="16px">
              <TextField
                name="promotion_active_day_threshold"
                label="Active Day Threshold"
                value={promotionDetails.promotion_active_day_threshold}
                onChange={handleInputChange}
                className={classes.textField}
                type="number"
              />
            </Box>
          </CardContent>
        </Card>
        <Box mt="16px" mb="16px">
          <Card>
            <CardContent>
              <Box justifyContent="center">
                <Flex mb="16px" justifyContent="space-between">
                  <Box>
                    <Typography variant="h6">Invitees</Typography>
                  </Box>
                  <Box>
                    <Button
                      onClick={toggleDrawer}
                      variant="outlined"
                      color="primary"
                    >
                      <Add />
                      Add Invitees
                    </Button>
                  </Box>
                </Flex>
                <Divider />
                <Box>
                  {userPromosLoading && <LoadingDots />}
                  {invitees.length === 0 && !userPromosLoading && (
                    <InviteesContainer>
                      <Typography variant="body1"> No invitees yet</Typography>
                    </InviteesContainer>
                  )}
                  {invitees.map((invitee) => (
                    <>
                      <UserListItem
                        style={{
                          height: '40px',
                          img: {
                            height: '30px',
                            width: '30px',
                          },
                        }}
                        key={invitee.user_id}
                        user={invitee}
                      >
                        <Delete
                          fontSize="small"
                          onClick={() => handleDeleteUserPromo(invitee.id)}
                        />
                      </UserListItem>
                      <Divider />
                    </>
                  ))}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box mt="26px">
          <Button
            type="submit"
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={handlePromoUpdate}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
      <UserPromoSearch
        toggleDrawer={toggleDrawer}
        isDrawerOpen={isDrawerOpen}
        invitees={invitees}
        handleAddInvitee={handleAddInvitee}
        handleDeleteUserPromo={handleDeleteUserPromo}
      />
    </PageLayout>
  );
};

export default withStyles(styles)(EditPromotion);
