import { useQuery } from 'react-query';
import { getBeltSize } from '../fetchers/belts';

export function useBeltSize(gymId) {
  const { data = [], isLoading, error } = useQuery(
    ['beltSizes', gymId],
    () => getBeltSize(gymId),
    {
      enabled: !!gymId,
      staleTime: Infinity,
    }
  );
  return { beltSizes: data, isLoading, error };
}
