import React from 'react';
import { Avatar, withStyles } from '@material-ui/core';

const styles = {
  avatar: {
    width: '40px',
    height: '40px',
  },
};

export const ListAvatar = ({ src, name = '', classes, style }) => {
  return (
    <Avatar
      style={{
        ...style,
      }}
      className={classes.avatar}
      src={src}
    >
      {name.length ? name[0] : ''}
    </Avatar>
  );
};

export default withStyles(styles)(ListAvatar);
