import { useQuery } from 'react-query';
import { fetchUserInfo, fetchUserStats } from '../fetchers/users';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { get } from 'lodash';

export function useUser(uid) {
  const { data, isLoading, error } = useQuery(
    ['user', uid],
    () => fetchUserInfo(uid),
    {
      enabled: !!uid,
      refetchOnWindowFocus: false,
    }
  );

  const isAdmin = get(data, 'permissions', []).includes('admin');
  const isKiosk = get(data, 'permissions', []).includes('kiosk');

  const [props, setProps] = useState({
    name: '',
    email: '',
    avatar_url: '',
    belt: {},
    belt_size: {},
    gym: {},
    jits_start_date: format(Date.now(), 'YYYY-MM-DD'),
    start_date: format(Date.now(), 'YYYY-MM-DD'),
    dob: format(Date.now(), 'YYYY-MM-DD'),
    description: '',
    errors: [],
    children: [],
    parent_id: null,
  });

  useEffect(() => {
    const setPropsData = (data) => {
      setProps({
        ...props,
        ...data,
      });
    };

    setPropsData(data);
  }, [data]);

  return {
    user: { ...props },
    setUser: setProps,
    loading: isLoading,
    error,
    isAdmin,
    isKiosk,
  };
}

export function useChild(parentUid) {
  const { data, isLoading, error } = useQuery(['user', parentUid], () =>
    fetchUserInfo(parentUid)
  );

  const [props, setProps] = useState({
    name: '',
    email: '',
    avatar_url: '',
    belt: {},
    belt_size: {},
    gym: {},
    jits_start_date: format(Date.now(), 'YYYY-MM-DD'),
    start_date: format(Date.now(), 'YYYY-MM-DD'),
    dob: format(Date.now(), 'YYYY-MM-DD'),
    description: '',
    errors: [],
    children: [],
    parent_id: null,
  });

  useEffect(() => {
    const setPropsData = (data) => {
      setProps({
        ...props,
        gym: get(data, 'gym'),
        parent_id: get(data, 'id'),
        email: get(data, 'email'),
      });
    };

    setPropsData(data);
  }, [data]);

  return {
    child: { ...props },
    setChild: setProps,
    loading: isLoading,
    error,
  };
}

export const useUserStats = (uid) => {
  const { data } = useQuery(['user-stats', uid], () => fetchUserStats(uid), {
    enabled: !!uid,
    refetchOnWindowFocus: false,
  });
  const [stats, setStats] = useState({});

  useEffect(() => {
    const setStatsData = (data) => {
      setStats(data);
    };

    setStatsData(data);
  }, [data]);
  return {
    stats,
  };
};
