import React, { useContext, useState } from 'react';
import PageLayout from '../../page-layout';
import { useUser } from '../../../lib/hooks/use-user';
import { useParams } from '@reach/router';
import { AppContext } from '../../../app';
import styled from 'styled-components';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Close from '@material-ui/icons/Close';
import { Box, Flex } from '@rebass/grid';
import { Button } from '@material-ui/core';
import UserTableList from '../components/user-table-list';
import { useDrawer } from '../../../lib/hooks/use-drawer';
import Export from '@material-ui/icons/CloudDownload';
import { Typography } from '@material-ui/core';
import { useIsMobile } from '../../../lib/hooks/use-is-mobile';
import {
  UserSearchFilters,
  FilterPanel,
} from '../components/user-search-filters';
import { useSearchFilters } from '../../../lib/hooks/use-search-filters';
import { LoadingDots } from '../../atom/loading-dots';

const IconWrapper = styled.div`
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 60px;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  font-size: 12px;
  padding: 16px;
  border-radius: 5px 5px;
  box-sizing: border-box;
`;

const ModalBody = styled.div`
  background: #303030;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;

export const GymUsers = () => {
  const { loggedInUserId } = useContext(AppContext);
  const { user = {}, loading } = useUser(loggedInUserId);
  const { gymUid } = useParams();
  const {
    isDrawerOpen: isFilterDrawerOpen,
    toggleDrawer: toggleFilterDrawer,
  } = useDrawer();
  const [isExporting, setIsExporting] = useState(false);
  const { isMobile } = useIsMobile();

  const {
    searchTerm,
    setSearchTerm,
    age,
    handleAgeChange,
    handleProgramChange,
    program,
    startDate,
    handleStartDateChange,
    endDate,
    handleEndDateChange,
    lastPromoted,
    setLastPromoted,
  } = useSearchFilters();

  const handleCsvExport = () => {
    setIsExporting(true);
    setTimeout(() => {
      setIsExporting(false);
    }, 1000);
  };

  return (
    <PageLayout pageTitle={`${'Gym Members'}`} dt>
      {loading || !user.id ? (
        <LoadingDots />
      ) : (
        <>
          <Flex justifyContent="space-between">
            <Box mb="50px" mt={4}>
              <Typography variant="h5">Gym Members</Typography>
              <Typography variant="subtitle1">
                Run reports on your gym members
              </Typography>
            </Box>
            {!isMobile && (
              <Flex alignItems="flex-end">
                <Button
                  style={{
                    color: '#03a9f4',
                  }}
                  variant="outlined"
                  onClick={handleCsvExport}
                  disabled={isExporting}
                >
                  <Box>Export CSV</Box>
                  <Flex ml="10px" alignItems="center">
                    <Export />
                  </Flex>
                </Button>
              </Flex>
            )}
          </Flex>
          <UserSearchFilters
            program={program}
            handleProgramChange={handleProgramChange}
            startDate={startDate}
            handleStartDateChange={handleStartDateChange}
            endDate={endDate}
            lastPromoted={lastPromoted}
            setLastPromoted={setLastPromoted}
            handleEndDateChange={handleEndDateChange}
            setSearchTerm={setSearchTerm}
            toggleFilterDrawer={toggleFilterDrawer}
          />
          <UserTableList
            searchTerm={searchTerm}
            gymUid={gymUid}
            filters={{ program, startDate, endDate, lastPromoted }}
            csv={isExporting}
          />
        </>
      )}
      <SwipeableDrawer
        anchor="bottom"
        open={isFilterDrawerOpen}
        onClose={toggleFilterDrawer}
        onOpen={() => {}}
        PaperProps={{
          style: {
            borderRadius: '20px 20px 0 0',
            height: '30vh',
            background: '#303030',
          },
        }}
        transitionDuration={{ enter: 300, exit: 250 }} // Adjust the animation duration (in milliseconds)
      >
        <Header>
          <IconWrapper onClick={toggleFilterDrawer}>
            <Close />
          </IconWrapper>
          <Box>
            <Typography
              variant="subtitle1"
              style={{
                marginRight: '10px',
              }}
            >
              Filters
            </Typography>
          </Box>
          <div />
        </Header>
        <ModalBody>
          <FilterPanel
            age={age}
            handleAgeChange={handleAgeChange}
            startDate={startDate}
            handleStartDateChange={handleStartDateChange}
            endDate={endDate}
            handleEndDateChange={handleEndDateChange}
          />
        </ModalBody>
      </SwipeableDrawer>
    </PageLayout>
  );
};

export default GymUsers;
