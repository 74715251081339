import { useQuery } from 'react-query';
import {
  fetchGymPromotions,
  fetchUserPromotions,
  fetchPromotion,
} from '../fetchers/promotions';

export const usePromotions = (gymId, filter) => {
  const { isLoading, error, data } = useQuery(
    ['promotions', gymId, filter],
    () => fetchGymPromotions(gymId, filter),
    {
      enabled: !!gymId,
      refetchOnWindowFocus: false,
    }
  );

  return {
    promotions: data,
    promosLoading: isLoading,
    error,
  };
};

export const usePromotion = (promoId) => {
  const { isLoading, error, data } = useQuery(
    ['promotion', promoId],
    () => fetchPromotion(promoId),
    {
      enabled: !!promoId,
      refetchOnWindowFocus: false,
    }
  );

  return {
    promotion: data,
    promoLoading: isLoading,
    error,
  };
};

export const useUserPromotions = (promoId) => {
  const { isLoading, error, data } = useQuery(
    ['user-promotions', promoId],
    () => fetchUserPromotions(promoId),
    {
      enabled: !!promoId,
      refetchOnWindowFocus: false,
    }
  );

  return {
    userPromotions: data,
    userPromosLoading: isLoading,
    error,
  };
};
