import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { Flex } from '@rebass/grid';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

// Styled component for ChartControls
const ChartControls = styled.div`
  font-size: 12px;
  color: ${({ selected }) => (selected ? '#fff' : '#a6a4a4')};
  font-weight: 600;
`;

const styles = {
  sectionPadding: {
    marginTop: '32px',
    padding: '0px 0',
  },
};

// The CheckInStats component
const CheckInStats = ({
  classes,
  sixWeekCount = 0,
  weekCount = 0,
  monthCount = 0,
  lastPromotionCount = 0,
}) => {
  return (
    <Card>
      <CardContent className={classes.statCard}>
        <Flex justifyContent="space-between">
          <Flex alignItems="center" flexDirection="column">
            <Box mt="6px">
              <Typography variant="h5">{sixWeekCount}</Typography>
            </Box>
            <ChartControls>Six week</ChartControls>
          </Flex>
          <Flex alignItems="center" flexDirection="column">
            <Box mt="6px">
              <Typography variant="h3">{lastPromotionCount}</Typography>
            </Box>
            <ChartControls selected>Toward next promotion </ChartControls>
          </Flex>

          <Flex alignItems="center" flexDirection="column">
            <Box mt="6px">
              <Typography variant="h5">{weekCount}</Typography>
            </Box>
            <ChartControls>This week</ChartControls>
          </Flex>
        </Flex>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(CheckInStats);
