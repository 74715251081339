import { fetchUserCheckins } from '../fetchers/checkins';
import { useQuery } from 'react-query';

export const useUserCheckins = (userId, gymId, startDate, endDate) => {
  const { data = [], isLoading, isError } = useQuery(
    [userId, gymId],
    () => fetchUserCheckins(userId),
    {
      enabled: !!userId,
    }
  );

  return {
    checkins: data,
    isLoading,
    isError,
  };
};
