import { useState, useEffect } from 'react';

export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isBetweenDesktopAndMobile, setIsBetweenDesktopAndMobile] = useState(
    false
  );

  const checkIsDesktop = () => {
    const isDeskTopSize = window.innerWidth > 1050;
    const isBetweenDesktopAndMobile =
      window.innerWidth < 1050 && window.innerWidth > 650;
    setIsDesktop(isDeskTopSize);
    setIsBetweenDesktopAndMobile(isBetweenDesktopAndMobile);

    return isDeskTopSize;
  };

  useEffect(() => {
    checkIsDesktop();

    const handleResize = () => {
      checkIsDesktop();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isDesktop: isDesktop,
    isBetweenDesktopAndMobile: isBetweenDesktopAndMobile,
  };
};
