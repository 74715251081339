import { action, observable, runInAction, computed } from 'mobx';
import firebase from 'firebase';
import {
  startOfWeek,
  addWeeks,
  isThisWeek,
  getDay,
  format,
  differenceInCalendarYears,
  getMonth,
} from 'date-fns';
import groupBy from 'lodash/groupBy';
import { belts } from '../../components/atom/belt';
import appConfig from '../../config/app';

const db = firebase.firestore();
const sendEmail = firebase.functions().httpsCallable('inviteEmail');
const settings = { timestampsInSnapshots: true };
db.settings(settings);
const userRef = db.collection('users');
const checkInRef = db.collection('checkins');
const storage = firebase.storage().ref();

class Auth {
  @observable today = Date.now();
  @observable startOfWeekDate = startOfWeek(this.today);
  @observable members = [];
  @observable usersChildren = [];
  @observable currentUserCheckins = [];
  @observable gymCheckins = [];
  @observable currentGym = {};
  @observable activeWeek = this.formattedDate(this.startOfWeekDate);
  @observable fullActiveWeek = this.formattedFullDate(this.startOfWeekDate);
  @observable activeWeekCount = 0;
  @observable rankFilter = [];
  @observable searchQuery = '';
  @observable inActiveUsers = false;
  @observable averageCheckins = { count: 3, status: 'greater' };

  async sendInviteEmail(data) {
    return sendEmail(data);
  }

  @action
  setActiveWeek = (week, fullFormattedWeek, weekCount) => {
    this.activeWeek = week;
    this.fullActiveWeek = fullFormattedWeek;
    this.activeWeekCount = weekCount;
  };

  @action
  setAverageCheckins = (options) => {
    this.averageCheckins = options;
  };

  @action
  searchUserByName(value) {
    this.searchQuery = value;
  }

  @action
  setRankFilter(filters) {
    this.rankFilter = filters;
  }

  @action
  setInActiveUsers(bool) {
    this.inActiveUsers = bool;
  }

  @computed
  get inActiveUsersValue() {
    return this.inActiveUsers ? 'inactive' : 'active';
  }

  @computed
  get nextWeek() {
    return format(addWeeks(this.fullActiveWeek, 1), 'MMM DD');
  }

  get thisWeekFormatted() {
    return format(this.fullActiveWeek, 'MMM DD');
  }

  async createChildUserAccount(user) {
    try {
      const userId = await userRef.doc();
      return userRef.doc(userId.uid).set({
        uid: userId.uid,
        ...user,
      });
    } catch (e) {
      console.log(e);
    }
  }

  addAvatarImage(file) {
    if (file && file.name) {
      const fileRef = storage.child(`users/${file.name}`);
      return fileRef
        .put(file)
        .then((res) => {
          return fileRef.getDownloadURL();
        })
        .catch((err) => console.log(err));
    }
  }

  @action
  getGymCheckins(gymId, from, to) {
    if (isNaN(from) || isNaN(to)) return;
    this.gymCheckins = [];
    return checkInRef
      .where('gymId', '==', `${gymId}`)
      .where('dateStamp', '>=', from)
      .where('dateStamp', '<=', to)
      .get()
      .then((res) => {
        const gymCheckins = [];
        res.docs.forEach((docs) => {
          gymCheckins.push(docs.data());
        });
        runInAction(() => {
          this.gymCheckins = gymCheckins;
        });
      });
  }

  @action
  getUserCheckins(gymId, userId, from, to) {
    this.currentUserCheckins = [];
    return checkInRef
      .where('gymId', '==', `${gymId}`)
      .where('uid', '==', `${userId}`)
      .where('dateStamp', '>=', from)
      .where('dateStamp', '<=', to)
      .get()
      .then((res) => {
        let checkins = [];
        res.docs.forEach((docs) => checkins.push(docs.data()));
        runInAction(() => {
          this.currentUserCheckins = checkins;
        });
      })
      .catch((err) => console.log(err));
  }

  @computed get userGroupedCheckins() {
    const groupedUsers = groupBy(this.gymCheckins, 'uid');
    return Object.keys(groupedUsers).map((user) => {
      let checkinTotal = groupedUsers[user].length;
      return groupedUsers[user].reduce((result, checkin) => {
        return {
          ...checkin,
          ...result,
          checkinTotal,
        };
      }, []);
    });
  }

  @computed get filteredCheckins() {
    // this is checking if
    if (this.inActiveUsers) {
      const groupedUsers = groupBy(this.gymCheckins, 'uid');
      const checkinArray = Object.keys(groupedUsers);
      return this.members.filter((member) => {
        const beltFilter =
          this.rankFilter.length === 0
            ? true
            : this.rankFilter.includes(member.rank);
        const userName = member.name.toUpperCase();
        const searchQuery = this.searchQuery.toUpperCase();
        return (
          !checkinArray.includes(member.uid) &&
          beltFilter &&
          userName.includes(searchQuery)
        );
      });
    } else {
      return this.userGroupedCheckins.filter((user) => {
        const beltFilter =
          this.rankFilter.length === 0
            ? true
            : this.rankFilter.includes(user.rank);
        const userName = user.name.toUpperCase();
        const searchQuery = this.searchQuery.toUpperCase();
        let checkinCountCheck;
        if (this.averageCheckins.status === 'less') {
          checkinCountCheck = user.checkinTotal < this.averageCheckins.count;
        } else {
          checkinCountCheck = user.checkinTotal > this.averageCheckins.count;
        }
        return (
          beltFilter && checkinCountCheck && userName.includes(searchQuery)
        );
      });
    }
  }

  @computed get weeklyActiveMemberCount() {
    const weekMap = [
      { x: 'Su', y: 0 },
      { x: 'M', y: 0 },
      { x: 'T', y: 0 },
      { x: 'W', y: 0 },
      { x: 'TH', y: 0 },
      { x: 'F', y: 0 },
      { x: 'S', y: 0 },
    ];

    const memberDayCheckinMap = [
      { day: 'Su', members: [] },
      { day: 'M', members: [] },
      { day: 'T', members: [] },
      { day: 'W', members: [] },
      { day: 'Th', members: [] },
      { day: 'F', members: [] },
      { day: 'S', members: [] },
    ];

    this.gymCheckins.forEach((checkin) => {
      if (isThisWeek(checkin.dateStamp)) {
        if (
          !memberDayCheckinMap[getDay(checkin.dateStamp)].members.includes(
            checkin.uid,
          )
        ) {
          let weekObj = weekMap[getDay(checkin.dateStamp)];
          memberDayCheckinMap[getDay(checkin.dateStamp)].members.push(
            checkin.uid,
          );
          weekObj.y++;
        }
      }
    });

    return weekMap;
  }

  formattedDate(d = new Date()) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${month}/${day}`;
  }

  formattedFullDate(d = new Date()) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    let year = String(d.getFullYear());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${month}/${day}/${year}`;
  }

  getFormattedUserCheckinByMonth(startMonth) {
    const startMonthDate = startOfWeek(new Date(2023, startMonth, 1));
    let memoizedStartMonthDate = startMonthDate;

    return Array.from({ length: 9 }).map(() => {
      const nextWeek = addWeeks(memoizedStartMonthDate, 1);
      const nextStartOfWeek = startOfWeek(nextWeek);

      // builds label
      const formattedDate = this.formattedDate(nextStartOfWeek);
      const fullFormattedDate = this.formattedFullDate(nextStartOfWeek);

      memoizedStartMonthDate = startOfWeek(nextStartOfWeek);

      // looks up checkins for the week by grouped week start date label
      const weekCheckinCount = this.checkinsByWeek[formattedDate]
        ? this.checkinsByWeek[formattedDate]
        : [];

      // gets weekly total checkin
      const weeklyTotalCheckins = weekCheckinCount.map((checkin) => {
        return getDay(checkin.dateStamp);
      });

      const activeWeekDays = weeklyTotalCheckins.filter((item, index) => {
        return weeklyTotalCheckins.indexOf(item) >= index;
      });

      return {
        dateStamps: activeWeekDays,
        label: formattedDate,
        fullDate: fullFormattedDate,
        weekCount: weekCheckinCount.length,
        activeDays: activeWeekDays.length,
      };
    });
  }

  @computed
  get getCheckinSlides() {
    const thisMonth = getMonth(new Date());
    // generate slides from begining of year to this month
    const slideNumberArray = Array.from({ length: thisMonth + 1 }).map(
      (m, i) => i,
    );
    // generate slides with labels and checkins.
    return slideNumberArray.reduce((result, month, index) => {
      if (index % 2 === 0) {
        result.push({
          labelOne: format(new Date(2023, month, 1), 'MMMM'),
          labelTwo: format(new Date(2023, month + 1, 1), 'MMMM'),
          checkins: this.getFormattedUserCheckinByMonth(month, month + 1),
          // [{ dateStamps: ''', label: '4/12', fullDate: 'somedate' , weekCount: 12}]
        });
      }
      return result;
    }, []);
  }

  @computed get memberDemographics() {
    const today = Date.now();
    let kids = 0;
    let adults = 0;
    this.members.forEach((member) => {
      const result = differenceInCalendarYears(
        new Date(today),
        new Date(member.dob),
      );
      if (result >= 18) {
        adults++;
      } else if (result !== 0 && result < 18) {
        kids++;
      }
    });
    return [{ x: 'Adults', y: adults }, { x: 'Kids', y: kids }];
  }

  @computed get adultBeltDemographics() {
    const beltGroup = groupBy(this.adults, 'rank');
    const beltObj = Object.keys(beltGroup).map((belt) => ({
      x: beltGroup[belt].length,
      y: beltGroup[belt].length,
    }));
    return beltObj;
  }

  @computed get kidBeltDemographics() {
    const beltGroup = groupBy(this.kids, 'rank');
    const beltObj = Object.keys(beltGroup).map((belt) => ({
      x: beltGroup[belt].length,
      y: beltGroup[belt].length,
    }));
    return beltObj;
  }

  @computed get kids() {
    const today = Date.now();
    return this.members.filter((member) => {
      const result = differenceInCalendarYears(
        new Date(today),
        new Date(member.dob),
      );
      return result > 0 && result < 18;
    });
  }

  @computed get adults() {
    const today = Date.now();
    return this.members.filter((member) => {
      const result = differenceInCalendarYears(
        new Date(today),
        new Date(member.dob),
      );
      return result >= 18;
    });
  }

  @computed get kidBeltColors() {
    const beltGroup = groupBy(this.kids, 'rank');
    const beltKeys = Object.keys(beltGroup).filter(
      (belt) => belt !== 'undefined',
    );
    return beltKeys.map((belt) => {
      if (belt && belts[belt]) return belts[belt].color;
      return '';
    });
  }

  @computed get adultBeltColors() {
    const beltGroup = groupBy(this.adults, 'rank');
    const beltKeys = Object.keys(beltGroup).filter(
      (belt) => belt !== 'undefined',
    );
    return beltKeys.map((belt) => {
      if (belt && belts[belt]) return belts[belt].color;
      return '';
    });
  }

  @computed
  get weekDaysFormatted() {
    const checkins = this.checkinsByWeek[this.activeWeek]
      ? this.checkinsByWeek[this.activeWeek]
      : [];
    const days = checkins.map((checkin) => {
      return {
        day: getDay(checkin.dateStamp),
      };
    });
    return groupBy(days, 'day');
  }

  @computed
  get checkinsByWeek() {
    return groupBy(this.currentUserCheckins, 'weekStartStamp');
  }

  @action
  async fetchUserInfo(id) {
    firebase.auth().onAuthStateChanged(async (authInfo) => {
      if (authInfo) {
        const userId = id;
        const fetchUser = await fetch(
          `${appConfig.baseApiUrl}/api/v1/users/${userId}`,
        );
        const userRes = await fetchUser.json();
        return userRes;
      } else {
        window.location.replace('/login');
      }
    });
  }

  async retrieveUserInfo(uid) {
    const user = userRef.doc(`${uid}`);
    return user
      .get()
      .then((userInfo) => {
        const data = userInfo.data();
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @action
  async fetchUsers(gymId) {
    const users = userRef.where('gymId', '==', `${gymId}`);
    this.members = [];
    return users.get().then((members) => {
      const memberCollection = [];
      members.forEach((member) => {
        memberCollection.push(member.data());
      });
      runInAction(() => {
        this.members = memberCollection;
      });
    });
  }
}

const authStore = new Auth();

export default authStore;
