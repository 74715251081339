import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchGymUsers } from '../fetchers/gyms';
import { useDebounce } from '../../components/atom/search';

export function useGymUsers(uid, searchTerm, params) {
  const debouncedInputValue = useDebounce(searchTerm, 300);
  const { data, isLoading, error } = useQuery(
    ['gym-users', debouncedInputValue, uid, params],
    () => fetchGymUsers(uid, debouncedInputValue, params),
    {
      refetchOnWindowFocus: true,
      enabled: !!uid,
    }
  );

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const setUsersData = (data) => {
      setUsers(data);
    };
    setUsersData(data);
  }, [data]);

  return {
    users,
    setUsers: setUsers,
    loading: isLoading,
    error,
  };
}
