import React, { useState, useContext, useEffect } from 'react';
import PageLayout from '../../page-layout';
import { useUser } from '../../../lib/hooks/use-user';
import { navigate, useParams } from '@reach/router';
import { AppContext } from '../../../app';
import { TextField } from '@material-ui/core';
import { Box } from '@rebass/grid';
import UserList from '../components/user-list';
import { LoadingDots } from '../../atom/loading-dots';

const KioskCheckin = () => {
  const { loggedInUserId } = useContext(AppContext);
  const { user = {}, isAdmin, isKiosk, loading } = useUser(loggedInUserId);
  const { gymUid } = useParams();

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (user.id && !isAdmin && !isKiosk) {
      navigate(`/profile/${user.uid}`);
    }
  }, [user]);

  return (
    <PageLayout pageTitle={`${'Check in'}`} dt>
      {loading || !user.id ? (
        <LoadingDots />
      ) : (
        <>
          <Box mt="22px" mb="22px">
            <TextField
              style={{ width: '100%' }}
              label="Search"
              variant="filled"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
          <UserList
            searchTerm={searchTerm}
            gymUid={gymUid}
            kiosk={true}
            setSearchTerm={setSearchTerm}
          />
        </>
      )}
    </PageLayout>
  );
};

export default KioskCheckin;
