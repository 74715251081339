import appConfig from '../../config/app';
import { getAuthHeaders } from './auth';

export async function fetchGymPromotions(gymId, filter = 'active') {
  const options = await getAuthHeaders();
  if (gymId) {
    try {
      const fetchPromotions = await fetch(
        `${appConfig.baseApiUrl}/api/v1/promotions?gym_id=${gymId}&filter=${filter}`,
        {
          headers: {
            ...options,
          },
        }
      );
      const promoResponse = await fetchPromotions.json();
      return promoResponse;
    } catch (error) {
      console.log(error);
    }
  } else {
    return { error: 'User not authenticated' };
  }
}

export async function fetchPromotion(promoId) {
  const options = await getAuthHeaders();
  if (promoId) {
    try {
      const fetchPromotions = await fetch(
        `${appConfig.baseApiUrl}/api/v1/promotions/${promoId}`,
        {
          headers: {
            ...options,
          },
        }
      );
      const promoResponse = await fetchPromotions.json();
      return promoResponse;
    } catch (error) {
      console.log(error);
    }
  } else {
    return { error: 'User not authenticated' };
  }
}

export const fetchUserPromotions = async (promoId) => {
  const options = await getAuthHeaders();
  if (promoId) {
    try {
      const fetchPromotions = await fetch(
        `${appConfig.baseApiUrl}/api/v1/user_promotions?promotion_id=${promoId}`,
        {
          headers: {
            ...options,
          },
        }
      );
      const promoResponse = await fetchPromotions.json();
      return promoResponse;
    } catch (error) {
      console.log(error);
    }
  } else {
    return { error: 'User not authenticated' };
  }
};
