import appConfig from '../../config/app';
import { getAuthHeaders } from './auth';

export async function fetchUserStats(uid) {
  const options = await getAuthHeaders();
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 4);
  if (uid) {
    try {
      const fetchUserStats = await fetch(
        `${appConfig.baseApiUrl}/api/v1/users/${uid}/user_stats`,
        {
          headers: {
            ...options,
          },
        },
      );
      const userRes = await fetchUserStats.json();
      return userRes;
    } catch (error) {
      console.log(error);
    }
  } else {
    return { error: 'User not authenticated' };
  }
}

export async function fetchUserInfo(uid) {
  const options = await getAuthHeaders();
  if (uid) {
    try {
      const fetchUser = await fetch(
        `${appConfig.baseApiUrl}/api/v1/users/${uid}`,
        {
          headers: {
            ...options,
          },
        },
      );
      const userRes = await fetchUser.json();
      return userRes;
    } catch (error) {
      console.log(error);
    }
  } else {
    return { error: 'User not authenticated' };
  }
}

export function fetchDisabledUsers(gym_id) {
  return fetch(
    `${appConfig.baseApiUrl}/api/v1/admin/${gym_id}/get_disabled_users`,
  )
    .then((response) => response.json())
    .catch((error) => console.error(error));
}
