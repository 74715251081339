import React, { useContext, useEffect } from 'react';
import PageLayout from '../../page-layout';
import { useUser } from '../../../lib/hooks/use-user';
import { navigate } from '@reach/router';
import { AppContext } from '../../../app';
import { Box } from '@rebass/grid';
import UserListItem from '../../organisim/user-list-item';
import { useDisabledUsers } from '../../../lib/hooks/use-disabled-users';
import { Button } from '@mui/material';
import { Divider } from '@material-ui/core';
import { enableUser } from '../../../lib/mutations/enable-user';
import { useMutation, useQueryClient } from 'react-query';
import { LoadingDots } from '../../atom/loading-dots';

const DisabledUsers = () => {
  const { loggedInUserId } = useContext(AppContext);
  const { user = {}, isAdmin, loading } = useUser(loggedInUserId);

  const { disabledUsers } = useDisabledUsers(user.gym_id);
  const queryClient = useQueryClient();
  const { mutate } = useMutation((data) => enableUser(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['disabled-users', user.gym_id]);
    },
  });

  useEffect(() => {
    if (user.id && !isAdmin) {
      navigate(`/profile/${user.uid}`);
    }
  }, [user]);

  const handleEnableClick = async (disabledUser) => {
    const requestBody = {
      enable_user: {
        user_id: disabledUser.id,
      },
    };
    mutate(requestBody);
  };

  return (
    <PageLayout pageTitle={`${'Disabled Users'}`} dt>
      {loading || !user.id ? (
        <LoadingDots />
      ) : (
        <Box mt="22px" mb="22px">
          {disabledUsers?.disabled_users?.length === 0 && (
            <Box>
              <h2>No disabled users</h2>
            </Box>
          )}
          {disabledUsers?.disabled_users?.map((disabledUser) => (
            <>
              <UserListItem key={disabledUser.id} user={disabledUser}>
                {disabledUser.parent_id ? (
                  <small> Child account </small>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => handleEnableClick(disabledUser)}
                  >
                    Enable
                  </Button>
                )}
              </UserListItem>
              <Divider />
            </>
          ))}
        </Box>
      )}
    </PageLayout>
  );
};

export default DisabledUsers;
