import React from 'react';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryClipContainer,
  VictoryLine,
  VictoryScatter,
} from 'victory';

export const LineChart = ({
  firstDataSet = [],
  maxCheckins = 10,
  sectionKey = 'week',
}) => {
  const firstArrayValue = [...firstDataSet.map((item) => item.y)];

  const upperAxisBound = Math.max(...firstArrayValue, maxCheckins);
  const lowerAxisBound = 0; // Setting the lower bound for gradient area

  return (
    <VictoryChart
      key={sectionKey}
      animate={{
        duration: 1500,
        onLoad: { duration: 500 },
      }}
      height={190}
      domainPadding={20}
      padding={{ top: 5, bottom: 50, left: 24, right: 0 }}
    >
      <defs>
        <linearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#03a9f4" stopOpacity={0.7} />
          <stop offset="100%" stopColor="transparent" stopOpacity={0.1} />
        </linearGradient>
      </defs>

      {/* Custom area component for gradient */}
      <VictoryArea
        data={firstDataSet.map((data) => {
          return { x: data.x, y: Math.max(data.y, lowerAxisBound) };
        })}
        interpolation="catmullRom"
        style={{
          data: { fill: 'url(#myGradient)' },
        }}
        groupComponent={
          <VictoryClipContainer clipPadding={{ top: 0, bottom: 0 }} />
        }
      />

      {/* Line component */}
      <VictoryLine
        animate={{
          duration: 1500,
          onLoad: { duration: 500 },
        }}
        data={firstDataSet}
        interpolation="catmullRom"
        style={{
          data: { stroke: '#03a9f4', strokeWidth: 2 },
        }}
      />

      {/* Scatter component */}
      <VictoryScatter
        data={firstDataSet}
        size={3}
        style={{ data: { fill: '#03a9f4' } }}
      />

      {/* Y-axis */}
      <VictoryAxis
        dependentAxis
        domain={[0, upperAxisBound]}
        tickCount={4}
        tickFormat={(x) => `${Math.round(x)}`}
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white' },
          grid: { stroke: 'gray', strokeDasharray: '2, 2' },
        }}
      />

      {/* X-axis */}
      <VictoryAxis
        style={{
          axis: { stroke: 'white' },
          tickLabels: { fill: 'white' },
        }}
      />
    </VictoryChart>
  );
};
