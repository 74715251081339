import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as serviceWorker from './serviceWorker';
const queryClient = new QueryClient();

// let deferredPrompt;

// const handleBeforeInstallPrompt = (event) => {
//   event.preventDefault();
//   deferredPrompt = event;
// };

// window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
// window.addEventListener('afterinstallprompt', showInstallPrompt);

// const showInstallPrompt = () => {
//   if (deferredPrompt) {
//     deferredPrompt.prompt();
//     deferredPrompt.userChoice.then((choiceResult) => {
//       if (choiceResult.outcome === 'accepted') {
//         console.log('User accepted the install prompt');
//       } else {
//         console.log('User dismissed the install prompt');
//       }
//       deferredPrompt = null;
//     });
//   }
// };

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
