import React from 'react';
import { useParams, useLocation, navigate } from '@reach/router';
import Drawer from '@material-ui/core/Drawer';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import UserTableList from './user-table-list';
import { ArrowBack } from '@material-ui/icons';
import { useSearchFilters } from '../../../lib/hooks/use-search-filters';
import { UserSearchFilters } from './user-search-filters';

const IconWrapper = styled.div`
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 50px;
  background: #03a9f4;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 16px;
  box-sizing: border-box;
`;

export const UserPromoSearch = ({
  invitees = [],
  handleAddInvitee,
  handleDeleteUserPromo,
  isDrawerOpen,
  toggleDrawer,
}) => {
  const {
    searchTerm,
    setSearchTerm,
    program,
    handleProgramChange,
    startDate,
    handleStartDateChange,
    endDate,
    handleEndDateChange,
    lastPromoted,
    setLastPromoted,
  } = useSearchFilters();

  const { gymUid } = useParams();
  const queryParams = new URLSearchParams(useLocation().search);
  const backUrl = queryParams.get('backUrl');

  const addInvitee = (user) => {
    handleAddInvitee(user);
  };

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={toggleDrawer}
      BackdropProps={{ invisible: true }}
      PaperProps={{ style: { width: '100%', background: '#303030' } }}
      transitionDuration={{ enter: 350, exit: 300 }} // Adjust the animation duration (in milliseconds)
    >
      <div>
        <Header>
          <IconWrapper onClick={() => toggleDrawer('')}>
            <ArrowBack />
          </IconWrapper>
          <Typography variant="h6">{'Invitees'}</Typography>
          <div />
        </Header>
        <Box p="24px" style={{ maxWidth: '1100px', margin: '0 auto' }}>
          <Box>
            <UserSearchFilters
              setSearchTerm={setSearchTerm}
              program={program}
              handleProgramChange={handleProgramChange}
              startDate={startDate}
              handleStartDateChange={handleStartDateChange}
              endDate={endDate}
              handleEndDateChange={handleEndDateChange}
              lastPromoted={lastPromoted}
              setLastPromoted={setLastPromoted}
              backTo={backUrl ? () => navigate(backUrl) : toggleDrawer}
            />
          </Box>
          <Box>
            <UserTableList
              filters={{
                program,
                startDate,
                endDate,
                lastPromoted,
              }}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              gymUid={gymUid}
              selectable={true}
              handleAddInvitee={addInvitee}
              handleDeleteUserPromo={handleDeleteUserPromo}
              invitees={invitees}
            />
          </Box>
        </Box>
      </div>
    </Drawer>
  );
};

export default UserPromoSearch;
