import { useEffect, useState } from 'react';
import CanvasCompress from 'canvas-compress';
import { addAvatarImage } from '../../lib/mutations/add-avatar';
import { useUser } from './use-user';
import { useQueryClient } from 'react-query';
import { getTime } from 'date-fns';
import { updateUserData } from '../../lib/mutations/update-user';

export const useUserFormEdit = (
  userObj,
  setUpdatedUser,
  submitCb,
  disableValidation = false
) => {
  const userId = userObj.uid;
  const { user = {} } = useUser(userId);
  const [editProfile, setEditProfile] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [updateErrors, setUpdateErrors] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    setUpdatedUser((currentUserState) => ({
      ...user,
      ...userObj,
      ...currentUserState,
    }));
  }, []);

  const updateTextField = (e, fieldName) => {
    const updatedState = {};
    const errors = user.errors;
    updatedState[`${fieldName}`] = e.target.value;
    const errorIndex = errors.indexOf(fieldName);
    if (errorIndex > -1 && fieldName !== 'degrees') {
      errors.splice(errorIndex, 1);
      updatedState['errors'] = errors;
    }

    setUpdatedUser((currentUser) => ({
      ...user,
      ...currentUser,
      ...updatedState,
    }));
  };

  const updateSelectField = (e, fieldName) => {
    const updatedState = {};
    updatedState[`${fieldName}`] = e.target.value;
    setUpdatedUser((currentUser) => ({
      ...user,
      ...currentUser,
      ...updatedState,
    }));
  };

  const uploadImage = (e) => {
    const { errors } = user;
    const file = e.target.files[0];
    const blob = file.slice(0, file.size, 'image/jpeg');

    const compressor = new CanvasCompress({
      type: CanvasCompress.MIME.JPEG,
      width: 500,
      height: 500,
      quality: 0.3,
    });

    compressor.process(blob).then(({ source, result }) => {
      const { blob } = result;
      const newFile = new File([blob], `${user.uid}.jpeg`, {
        type: 'image/jpeg',
      });
      if (newFile) {
        setUpdatedUser((currentUser) => ({
          ...user,
          ...currentUser,
          avatar_url: '',
        }));
        setIsUploadingImage(true);
        addAvatarImage(newFile)
          .then((avatar) => {
            const errorIndex = errors.indexOf('avatar_url');
            if (errorIndex > -1) {
              errors.splice(errorIndex, 1);
            }
            setUpdatedUser((currentUser) => ({
              ...user,
              ...currentUser,
              errors,
              avatar_url: avatar,
            }));
            setIsUploadingImage(false);
          })
          .catch((err) => {
            console.log(err);
            setIsUploadingImage(false);
          });
      }
    });
  };

  const queryClient = useQueryClient();

  const submitForm = (form) => {
    const formData = Object.assign({}, form);
    const errors = [];

    const includedFields = disableValidation
      ? []
      : [
          'name',
          'email',
          'avatar_url',
          'belt',
          'belt_size',
          'gym',
          'jits_start_date',
          'start_date',
          'dob',
          'description',
        ];

    Object.keys(formData).forEach((key) => {
      if (key === 'jits_start_date' || key === 'start_date' || key === 'dob') {
        formData[key] = getTime(formData[key]);
      }
      if (!formData[key] && includedFields.includes(key)) {
        errors.push(key);
      } else {
        const errorIndex = errors.indexOf(key);
        if (errorIndex > -1) {
          errors.splice(errorIndex, 1);
        }
      }
    });

    if (errors.length > 0) {
      setUpdatedUser((currentUser) => ({
        ...user,
        ...currentUser,
        errors,
      }));

      return;
    } else {
      delete formData['errors'];
      setUpdatedUser((currentUser) => ({
        ...user,
        ...currentUser,
        errors,
      }));
    }

    setIsUpdatingUser(true);
    setUpdateErrors(false);

    updateUserData(formData, userId)
      .then((res) => {
        setIsUpdatingUser(false);
        submitCb('success');
        setIsFormDirty(false);
        queryClient.invalidateQueries(['user', userId]);
      })
      .catch((err) => {
        console.log(err);
        setIsUpdatingUser(false);
        setUpdateErrors(true);
        setIsFormDirty(false);
        submitCb('false');
      });
  };

  return {
    editProfile,
    setEditProfile,
    isUploadingImage,
    setIsUploadingImage,
    isUpdatingUser,
    setIsUpdatingUser,
    updateErrors,
    setUpdateErrors,
    updateTextField,
    updateSelectField,
    isFormDirty,
    setIsFormDirty,
    submitForm,
    uploadImage,
  };
};
