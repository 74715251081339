import React from 'react';
import { Box, Flex } from '@rebass/grid';

import { Calendar } from 'react-multi-date-picker';

import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { LoadingDots } from '../../atom/loading-dots';

const CustomDateChip = styled.div`
  background-color: #0074d9;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  display: flex;
  aligh-items: center;
  justify-content: center;
  height: 25px;
  margin: 8px 0;
`;

const DatePanelWrapper = styled.div`
  border-left: 1px solid #5a5858;
  margin-left: 16px;
  padding: 0px 16px;
  height: 300px;
  overflow-y: auto;
`;

const CalendarWrapper = styled.div`
  height: 300px;
  .rmdp-top-class {
    background: #424242;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
  }
  .rmdp-header-values,
  .rmdp-panel-header,
  .rmdp-day {
    color: #fff;
  }

  .rmdp-shadow {
    box-shadow: none;
  }

  .checkin-date {
    background-color: grey !important;
    color: white !important;
  }
`;

function CustomDatePanel({
  newCheckinDates,
  handleRemoveCheckin,
  isLoading,
  ...props
}) {
  return (
    <DatePanelWrapper>
      <Flex
        mt="16px"
        mb="16px"
        justifyContent="center"
        style={{ textAlign: 'center' }}
      >
        <Typography variant="body2">Selected Dates</Typography>
      </Flex>
      <Box>
        {isLoading && <LoadingDots />}
        {!isLoading &&
          newCheckinDates.map((date) => {
            return (
              <CustomDateChip>
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box ml="8px">
                    <Typography variant="body2">{date}</Typography>
                  </Box>
                  <Flex alignItems="center" mr="6px">
                    <CloseIcon
                      onClick={() => handleRemoveCheckin(date)}
                      style={{ fontSize: '16px', cursor: 'pointer' }}
                    />
                  </Flex>
                </Flex>
              </CustomDateChip>
            );
          })}
      </Box>
    </DatePanelWrapper>
  );
}

export const AddCheckinsPanel = ({
  existingCheckins,
  newCheckins,
  disableCalendarDays,
  handleNewCheckins,
  handleRemoveCheckin,
  isLoading,
}) => {
  return (
    <Flex>
      <CalendarWrapper>
        <Calendar
          multiple
          value={[...existingCheckins, ...newCheckins]}
          format="MM/DD/YYYY"
          onChange={handleNewCheckins}
          mapDays={disableCalendarDays}
        />
      </CalendarWrapper>
      <Box width="200px">
        <CustomDatePanel
          newCheckinDates={newCheckins}
          handleRemoveCheckin={handleRemoveCheckin}
          isLoading={isLoading}
        />
      </Box>
    </Flex>
  );
};

export default AddCheckinsPanel;
