import React, { useContext } from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { CardContent, TextField, Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { Box, Flex } from '@rebass/grid';
import { useIsMobile } from '../../../lib/hooks/use-is-mobile';
import { Button } from '@mui/material';
import { useUser } from '../../../lib/hooks/use-user';
import { AppContext } from '../../../app';
import { useGymPrograms } from '../../../lib/hooks/use-gym-program';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

function RadioButtonsGroup({ setLastPromoted, lastPromoted }) {
  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="lastPromoted"
      onChange={() => setLastPromoted(!lastPromoted)}
      name="radio-buttons-group"
    >
      <Flex justifyContent="flex-end">
        <FormControlLabel
          value="lastPromoted"
          control={<Radio sx={{ color: 'white' }} />}
          label="Last Promotion"
        />
        <FormControlLabel
          value="date"
          control={<Radio sx={{ color: 'white' }} />}
          label="Date Range"
        />
      </Flex>
    </RadioGroup>
  );
}

const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'search filter';
  grid-gap: 16px;

  .MuiFormControl-root {
    width: 100%;
  }

  @media (max-width: 910px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'search'
      'filter';
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'search filter-icon';
  }
`;

const FilterControls = styled.div`
  display: grid;
  grid-template-areas:
    'age s-date e-date'
    '... radio radio';
  grid-gap: 16px;
  @media (max-width: 651px) {
    grid-template-areas:
      'age age'
      's-date e-date'
      'radio radio';
  }
`;

const GridArea = styled.div`
  grid-area: ${(props) => props.area};
`;

export const FilterPanel = ({
  program,
  handleProgramChange,
  startDate,
  handleStartDateChange,
  endDate,
  handleEndDateChange,
  lastPromoted,
  setLastPromoted,
}) => {
  const { loggedInUserId } = useContext(AppContext);
  const { user: currentUser } = useUser(loggedInUserId);
  const { gymPrograms = [] } = useGymPrograms(currentUser?.gym?.id);
  return (
    <FilterControls>
      <GridArea area="age">
        <FormControl sx={{ color: 'white', minWidth: 120, width: '100%' }}>
          <InputLabel sx={{ color: 'white' }}>Filter by Program</InputLabel>
          <Select
            value={program}
            label="Filter by Program"
            onChange={handleProgramChange}
            variant="outlined"
            sx={{ color: 'white' }}
          >
            <MenuItem value={null}>
              <em>All</em>
            </MenuItem>
            {gymPrograms.map((gymProgram) => (
              <MenuItem value={gymProgram?.id}>{gymProgram?.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridArea>
      <GridArea area="s-date">
        <TextField
          type="date"
          value={startDate}
          onChange={(e) => handleStartDateChange(e)}
          variant="outlined"
          label="Start Date"
          style={{ width: '100%' }}
          disabled={lastPromoted}
        />
      </GridArea>
      <GridArea area="e-date">
        <TextField
          type="date"
          value={endDate}
          variant="outlined"
          onChange={(e) => handleEndDateChange(e)}
          label="End Date"
          style={{ width: '100%' }}
          disabled={lastPromoted}
        />
      </GridArea>
      <GridArea area="radio">
        <RadioButtonsGroup
          setLastPromoted={setLastPromoted}
          lastPromoted={lastPromoted}
        />
      </GridArea>
    </FilterControls>
  );
};

export const UserSearchFilters = ({
  handleProgramChange,
  program,
  startDate,
  handleStartDateChange,
  endDate,
  handleEndDateChange,
  setLastPromoted,
  lastPromoted,
  setSearchTerm,
  toggleFilterDrawer,
  backTo,
}) => {
  const { isMobile } = useIsMobile();
  return (
    <Box mt="22px" mb="22px">
      {backTo && (
        <Flex mb="16px" justifyContent="space-between">
          <Typography variant="h6">Add Invitees</Typography>
          <Button onClick={backTo} variant="outlined" color="primary">
            Save changes
          </Button>
        </Flex>
      )}
      <Card>
        <CardContent>
          <Flex justifyContent="space-between" mb="16px">
            <Typography variant="h6">Filter Members</Typography>
          </Flex>
          <FilterWrapper>
            <GridArea area="search">
              <Box>
                <TextField
                  sx={{ width: '100%' }}
                  label="Search by name"
                  variant="outlined"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Box>
            </GridArea>

            {!isMobile && (
              <FilterPanel
                program={program}
                handleProgramChange={handleProgramChange}
                startDate={startDate}
                handleStartDateChange={handleStartDateChange}
                endDate={endDate}
                handleEndDateChange={handleEndDateChange}
                lastPromoted={lastPromoted}
                setLastPromoted={setLastPromoted}
              />
            )}
            {isMobile && (
              <GridArea area="filter-icon">
                <Button
                  sx={{
                    color: 'white',
                    borderColor: '#6e6e6e',
                    height: '56px',
                  }}
                  onClick={toggleFilterDrawer}
                  variant="outlined"
                >
                  <FilterList />
                </Button>
              </GridArea>
            )}
          </FilterWrapper>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserSearchFilters;
