import React, { useContext, useEffect, useState } from 'react';
import PageLayout from '../../page-layout';
import { Box, Flex } from '@rebass/grid';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

import { Link } from '@reach/router';
import { useUser } from '../../../lib/hooks/use-user';
import { AppContext } from '../../../app';
import { Promotion } from '../components/promotion';

import Drawer from '@material-ui/core/Drawer';
import ArrowBack from '@material-ui/icons/ArrowBack';
import useDrawer from '../../../lib/hooks/use-drawer';
import { usePromotions } from '../../../lib/hooks/use-promotions';
import { useLocation } from '@reach/router';

import { PromotionInfo } from '../components/promotion-info';
import { Add } from '@material-ui/icons';
import { LoadingDots } from '../../atom/loading-dots';
import { ButtonGroup } from '@material-ui/core';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  height: 50px;
  background: #03a9f4;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 16px;
  box-sizing: border-box;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const CardWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  .MuiCard-root {
    &:hover {
      transform: scale(1.01);
      transition: transform 0.2s;
      cursor: pointer;
    }
  }
`;

const styles = {
  textField: {
    width: '100%',
  },
  button: {
    width: '100%',
    margin: '16px 0 8px',
  },
  border: {
    borderBottom: '1px solid #5a5858',
  },
  icon: {
    width: '80px',
    height: '80px',
  },
  forgotPin: {
    width: '280px',
  },
};

const Promotions = (props) => {
  const { classes = {} } = props;
  const [promo, setPromo] = useState({});
  const { loggedInUserId } = useContext(AppContext);
  const { user = {} } = useUser(loggedInUserId);
  const { isDrawerOpen, toggleDrawer, openDrawer } = useDrawer();
  const queryParams = new URLSearchParams(useLocation().search);
  const promoId = queryParams.get('promoId');
  const [promoFilter, setPromoFilter] = useState('active');
  const { promotions = [], promosLoading = true } = usePromotions(
    user?.gym?.id,
    promoFilter
  );

  useEffect(() => {
    if (promoId && promotions.length > 0) {
      handlePromoUrl(promoId);
    }
  }, [promotions]);

  const handlePromoFilterClick = (filter) => {
    setPromoFilter(filter);
  };

  const handlePromoUrl = (id) => {
    const idNum = parseInt(id);
    const activePromo = promotions.find((promo) => promo.id === idNum);
    if (activePromo) {
      setPromo(activePromo);
      openDrawer();
    }
  };

  const handlePromoClick = (promo) => {
    setPromo(promo);
    toggleDrawer();
  };

  return (
    <PageLayout selected="Children" pageTitle="Promotions">
      <Flex
        mb="50px"
        mt={4}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box>
          <Typography variant="h5">Promotions</Typography>
          <Typography variant="subtitle1">
            Create and manage promotions for <strong> {user.gym.name} </strong>
          </Typography>
        </Box>
        <Box>
          <Link to="new">
            <Button color="primary" variant="outlined">
              <Box mr="4px" ml="8px">
                <Typography variant="button">Create</Typography>
              </Box>
              <Add />
            </Button>
          </Link>
        </Box>
      </Flex>
      <Box>
        <ButtonGroup variant="outlined" aria-label="Basic button group">
          <Button
            color={promoFilter === 'active' ? 'primary' : 'default'}
            onClick={() => handlePromoFilterClick('active')}
          >
            Active
          </Button>
          <Button
            color={promoFilter === 'completed' ? 'primary' : 'default'}
            onClick={() => handlePromoFilterClick('completed')}
          >
            Completed
          </Button>
          <Button
            color={promoFilter === 'all' ? 'primary' : 'default'}
            onClick={() => handlePromoFilterClick('all')}
          >
            All
          </Button>
        </ButtonGroup>
      </Box>
      {promosLoading && <LoadingDots />}
      {promotions.map((promo) => (
        <CardWrapper key={`${promo.id}-${promo.name}`} mt="16px">
          <Card onClick={() => handlePromoClick(promo)}>
            <CardContent>
              <PromotionInfo promo={promo} />
            </CardContent>
          </Card>
        </CardWrapper>
      ))}
      <Link to="new">
        <Button className={classes.button} variant="outlined" color="primary">
          Create New Promotion
        </Button>
      </Link>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        BackdropProps={{ invisible: false }}
        PaperProps={{
          style: { width: '100%', background: '#303030' },
        }}
        transitionDuration={{ enter: 350, exit: 300 }} // Adjust the animation duration (in milliseconds)
      >
        <div>
          <Header>
            <IconWrapper onClick={toggleDrawer}>
              <ArrowBack />
            </IconWrapper>
            <Typography variant="h6">{'Promotion'}</Typography>
            <div />
          </Header>
          <Box p="24px">
            <Box mb="42px">
              <Promotion promotion={promo} />
            </Box>
          </Box>
        </div>
      </Drawer>
    </PageLayout>
  );
};

export default withStyles(styles)(Promotions);
