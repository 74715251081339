import appConfig from '../../config/app';
import { getAuthHeaders } from './auth';

export async function getDashboardData(gymId) {
  const options = await getAuthHeaders();
  return fetch(`${appConfig.baseApiUrl}/api/v1/dashboard/stats/${gymId}`, {
    headers: {
      ...options,
    },
  })
    .then((data) => data.json())
    .then((data) => data)
    .catch((error) => error);
}
