import React from 'react';
import PageLayout from './page-layout';
import { Link, useParams } from '@reach/router';
import { withStyles } from '@material-ui/core';
import { navigate } from '@reach/router';
import { get } from 'lodash';
import { useUser } from '../lib/hooks/use-user';
import { useBeltSize } from '../lib/hooks/use-belt-size';
import { useGymPrograms } from '../lib/hooks/use-gym-program';

import ProfileForm from './organisim/profile-form';
import { useUserFormEdit } from '../lib/hooks/use-user-form-edit';

const styles = {
  textField: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  avatar: {
    margin: '8px',
    width: '100px',
    height: '100px',
    border: '4px solid #03a9f4',
  },
  inputFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    position: 'absolute',
    zIndex: '-1',
  },
};

const EditProfile = (props) => {
  const { classes, signup } = props;
  const { userId } = useParams();
  const { user = {}, setUser } = useUser(userId);
  const { beltSizes = [] } = useBeltSize(get(user, 'gym.id'));
  const { gymPrograms = [] } = useGymPrograms(get(user, 'gym.id'));

  const onSubmitCallback = (status) => {
    if (status === 'success') {
      navigate(`/profile/${user.uid}`);
    }
  };

  const {
    updateTextField,
    updateSelectField,
    updateErrors,
    uploadImage,
    isUpdatingUser,
    isUploadingImage,
    isFormDirty,
    setIsFormDirty,
    submitForm,
  } = useUserFormEdit(user, setUser, onSubmitCallback);

  const cancel = (uid) => {
    return (
      <Link to={`/profile/${uid}`}>
        <span> Cancel </span>
      </Link>
    );
  };

  return (
    <PageLayout
      pageTitle="Edit Profile"
      leftButton={signup ? <div /> : cancel(user.uid)}
    >
      <ProfileForm
        submitForm={submitForm}
        classes={classes}
        user={user}
        gymPrograms={gymPrograms}
        beltSizes={beltSizes}
        setState={setUser}
        updateTextField={updateTextField}
        updateSelectField={updateSelectField}
        upDateErrors={updateErrors}
        uploadImage={uploadImage}
        isUpdatingUser={isUpdatingUser}
        isImageUploading={isUploadingImage}
        isFormDirty={isFormDirty}
        setFormIsDirty={setIsFormDirty}
        signup={signup}
      />
    </PageLayout>
  );
};

export default withStyles(styles)(EditProfile);
